import {
	capitalize,
	formatCurrency,
	grammarInflection,
	removeDuplicateBlankSpaces
} from '@SHARED/utils/index';

import type { DisplayAddress, Property } from '@SHARED/core/entities/Property';

const areaOptionsByPropertyType = {
	house: 'construída',
	condo_house: 'construída'
} as Record<string, string>;

const totalAreaOptionsByPropertyType = {
	house: 'terreno',
	condo_house: 'terreno'
} as Record<string, string>;

export const PropertyService = {
	getAddressText(
		{ ad, address, number, region }: Property,
		minisiteDisplayAddress: DisplayAddress | null = null
	): string {
		const displayAddressOption = minisiteDisplayAddress || ad.displayAddress;

		const addressOptions = {
			region: `${region}`,
			street: `${address} - ${region}`,
			full: `${address}, ${number} - ${region}`
		} as Record<DisplayAddress, string>;

		if (!address) return addressOptions.region;
		if (!number) return addressOptions.street;

		return addressOptions[displayAddressOption];
	},
	getTitle({
		ad,
		propertyType,
		area,
		bedrooms,
		suites,
		region
	}: Property): string {
		if (ad.title?.trim()) return removeDuplicateBlankSpaces(ad.title.trim());

		const bedroomsAndOrSuitesSentence = getBedroomsAndOrSuitesSentence(
			bedrooms,
			suites,
			true
		);

		const shouldHaveCumulativeConjunctionAfterAreaSentence =
			!!bedroomsAndOrSuitesSentence;

		const optionalCumulativeConjunction =
			shouldHaveCumulativeConjunctionAfterAreaSentence ? 'e' : '';

		const uncaptalizedText = `${propertyType.name} com ${area} m² ${optionalCumulativeConjunction} ${bedroomsAndOrSuitesSentence} à venda no bairro ${region}`;

		const capitalizedText = capitalize(uncaptalizedText);

		return removeDuplicateBlankSpaces(capitalizedText).trim();
	},
	getDescription({
		ad,
		propertyType,
		region,
		area,
		bedrooms,
		suites,
		parkingSpots
	}: Property): string {
		const adDescription = ad.description?.trim();

		if (adDescription) return removeDuplicateBlankSpaces(adDescription);

		const parkingSpotsSentence = getParkingSpotsSentence(parkingSpots);

		const shouldHaveACommaAfterBedroomsAndOrSuitesSentence =
			!!parkingSpotsSentence;

		const bedroomsAndOrSuitesSentence = getBedroomsAndOrSuitesSentence(
			bedrooms,
			suites,
			shouldHaveACommaAfterBedroomsAndOrSuitesSentence
		);

		const optionalCumulativeConjunction =
			parkingSpotsSentence && bedroomsAndOrSuitesSentence ? 'e' : '';

		if (!bedroomsAndOrSuitesSentence && !parkingSpotsSentence) {
			const uncaptalizedText = `${propertyType.name} no bairro ${region} com ${area} m² privativos.`;

			const capitalizedText = capitalize(uncaptalizedText);

			return removeDuplicateBlankSpaces(capitalizedText).trim();
		}

		const uncaptalizedText = `${propertyType.name} no bairro ${region}.\nCom ${area} m² privativos, possui ${bedroomsAndOrSuitesSentence} ${optionalCumulativeConjunction} ${parkingSpotsSentence}`;

		const capitalizedText = capitalize(uncaptalizedText);

		const sanitizedText = removeDuplicateBlankSpaces(capitalizedText).trim();

		return `${sanitizedText}.`;
	},
	getAreaInfoText(area: number | null): string {
		return area === null ? '-' : `${area.toLocaleString('pt-BR')} m²`;
	},
	getCurrencyInfoText(number: number | null, includeCents = false): string {
		return number === null
			? 'Não informado'
			: formatCurrency(number, includeCents);
	},
	getNumberInfoText(number: number | null): string {
		return number === null ? '-' : number.toString();
	},
	getSquareMeterPrice({ askingPrice, rentPrice, area }: Property): number {
		const price = askingPrice || rentPrice || 0;

		return price / area;
	},
	getAreaLabel({ propertyType }: Property) {
		const defaultAreaLabel = 'útil';

		return (
			areaOptionsByPropertyType[propertyType.identifier] || defaultAreaLabel
		);
	},
	getTotalAreaLabel({ propertyType }: Property) {
		const defaultTotalAreaLabel = 'total';

		return (
			totalAreaOptionsByPropertyType[propertyType.identifier] ||
			defaultTotalAreaLabel
		);
	},
	getPropertyWatermarkUrlForOgImage(properties: Property[]): string | null {
		const firstPropertyWithAvailableImage = properties.find(property => {
			if (!property.images.length) return false;

			if (property.thumbnailUrl) return true;

			const firstImage = property.images[0];

			// * Imagens de parceiro com marca d'água têm uma URL maior e com a query string "x-watermark_url",
			// * enquanto as imagens da imobiliária dona do imóvel não têm essa query string.
			const propertyBelongsToTheMinisiteOwner =
				!firstImage.watermarkUrl.includes('x-watermark_url');

			return propertyBelongsToTheMinisiteOwner;
		});

		if (!firstPropertyWithAvailableImage) return null;

		const { thumbnailUrl, images } = firstPropertyWithAvailableImage;

		return thumbnailUrl || images[0].watermarkUrl;
	},
	getPropertyDataForSeoTexts(
		properties: Property[],
		companyName: string
	): {
		title: string;
		description: string;
	} | null {
		if (!properties.length) return null;

		if (properties.length === 1) {
			const [property] = properties;

			const titleTextSecondPart = [property.ad.title, property.commercialId]
				.filter(Boolean)
				.join(' - ');

			const descriptionData = [
				property.propertyType.name,
				property.region,
				`${property.area} m²`,
				getBedroomsAndOrSuitesSentence(null, property.suites),
				getParkingSpotsSentence(property.parkingSpots)
			];

			const description = descriptionData.filter(Boolean).join(' - ');

			return {
				title: `${companyName} | ${titleTextSecondPart}`,
				description
			};
		}

		const titleTextSecondPart = `Seleção com ${properties.length} imóveis`;

		const description = properties
			.map(
				property =>
					`${property.propertyType.name} - ${property.region} - ${property.area} m²`
			)
			.join(' • ');

		return {
			title: `${companyName} | ${titleTextSecondPart}`,
			description
		};
	},
	removeImageUrlFromAllPropertiesExceptForTheFirstImage(
		properties: Property[]
	): Property[] {
		const indexOfFirstPropertyWithImage = properties.findIndex(
			property => property.images.length > 0
		);

		return properties.map((property, propertyIndex) => ({
			...property,
			images: property.images.map((image, imageIndex) => ({
				...image,
				url:
					propertyIndex === indexOfFirstPropertyWithImage && imageIndex === 0
						? image.url
						: null
			}))
		}));
	},
	removeImageUrlFromAllProperties(properties: Property[]): Property[] {
		return properties.map(property => ({
			...property,
			images: property.images.map(image => ({
				...image,
				url: null
			}))
		}));
	}
};

function getBedroomsSentence(numberOfBedrooms: number | null): string {
	if (!numberOfBedrooms) return '';

	return `${numberOfBedrooms} ${grammarInflection(
		numberOfBedrooms,
		'quarto',
		'quartos'
	)}`;
}

function getSuitesSentence(numberOfSuites: number | null): string {
	if (!numberOfSuites) return '';

	return `${numberOfSuites} ${grammarInflection(
		numberOfSuites,
		'suíte',
		'suítes'
	)}`;
}

function getBedroomsAndOrSuitesSentence(
	numberOfBedrooms: number | null,
	numberOfSuites: number | null,
	addCommaAfterAppositiveSentence = false
): string {
	const bedroomsSentence = getBedroomsSentence(numberOfBedrooms);

	const suitesSentence = getSuitesSentence(numberOfSuites);

	if (bedroomsSentence && suitesSentence) {
		const sentence = `${bedroomsSentence}, sendo ${suitesSentence}`;

		return addCommaAfterAppositiveSentence ? `${sentence},` : sentence;
	}

	if (bedroomsSentence) return bedroomsSentence;

	if (suitesSentence) return suitesSentence;

	return '';
}

function getParkingSpotsSentence(numberOfParkingSpots: number | null): string {
	if (!numberOfParkingSpots) return '';

	return `${numberOfParkingSpots} ${grammarInflection(
		numberOfParkingSpots,
		'vaga',
		'vagas'
	)}`;
}
