<template>
	<section>
		<RelatedPropertyDataList
			v-if="availablePropertyTypesLinks.length"
			:title="`Procure por tipos de imóveis em ${actualRegion}`"
			:links="availablePropertyTypesLinks"
		/>

		<RelatedPropertyDataList
			v-if="nearbyRegionsLinks.length"
			:title="`Procure por bairros próximos a ${actualRegion}`"
			:links="nearbyRegionsLinks"
		/>
	</section>
</template>

<script setup lang="ts">
import type { RelatedPropertyDataListItem } from '@SHARED/components/atoms/RelatedPropertyDataList.vue';

import { PropertiesFiltersPresenter } from '@SHARED/presenters/PropertiesFiltersPresenter';
import RelatedPropertyDataList from '@SHARED/components/atoms/RelatedPropertyDataList.vue';

import { capitalize } from '@SHARED/utils';

type RelatedPropertyDataSectionProps = {
	actualRegion: string;
	nearbyRegions: string[];
	availablePropertyTypes: string[];
};

defineOptions({ name: 'RelatedPropertyDataSection' });

const props = defineProps<RelatedPropertyDataSectionProps>();

const availablePropertyTypesLinks = computed<RelatedPropertyDataListItem[]>(
	() =>
		props.availablePropertyTypes.map(propertyType => {
			const text = `${capitalize(propertyType)} em ${props.actualRegion}`;

			const href = PropertiesFiltersPresenter.getPathFromFilter({
				propertyTypes: [propertyType],
				regions: [props.actualRegion]
			});

			return { text, href };
		})
);

const nearbyRegionsLinks = computed<RelatedPropertyDataListItem[]>(() =>
	props.nearbyRegions.map(region => ({
		text: region,
		href: PropertiesFiltersPresenter.getPathFromFilter({ regions: [region] })
	}))
);
</script>

<style lang="scss" scoped></style>
