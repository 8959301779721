<template>
	<div class="page-wrapper bg-white">
		<ModalContainer
			v-show="shouldShowPropertyDetailsModal"
			variant="property-view"
			@close-modal="closePropertyDetailsModal"
		>
			<button
				class="close-property-view-button"
				data-rounded="pill"
				@click="closePropertyDetailsModal"
			>
				<CloseIcon />
			</button>
			<PropertyDetails
				:property="activeProperty"
				variant="default"
				:get-in-touch-url="null"
				:source="isActivePropertyFromRecommendation ? 'base' : 'recommendation'"
				class="modal-property-view"
				:main-carousel-height="{
					desktop: '60vh',
					mobile: '40vh'
				}"
				:partnership-request="propertyDetailsPartnershipRequestData"
				:show-schedule-visit-buttons="
					websiteConfig.globalSettings.enableVisitScheduling
				"
				@open-schedule-property-visit-modal="
					() =>
						openScheduleVisitModalForProperty(
							activeProperty,
							isActivePropertyFromRecommendation
						)
				"
				@toggle-image-carousel-modal="toggleImageCarouselModal"
				@open-image-gallery="
					() =>
						openGalleryForProperty(
							activeProperty,
							'photos',
							isActivePropertyFromRecommendation
						)
				"
				@open-video-gallery="
					() =>
						openGalleryForProperty(
							activeProperty,
							'videos',
							isActivePropertyFromRecommendation
						)
				"
				@click-share-link="trackShareButtonClick()"
			/>
		</ModalContainer>

		<ClientOnly v-if="activeProperty">
			<SchedulePropertyVisitFormModal
				v-show="shouldShowScheduleVisitModal"
				v-model:has-message-been-sent="hasSchedulePropertyVisitMessageBeenSent"
				class="schedule-property-view-modal"
				is-minisite-context
				:property-commercial-id="activeProperty.commercialId"
				:property-price="propertyPricingText"
				:property-information="propertyInfos"
				:property-thumbnail="propertyThumbnail"
				:property-title="propertyTitle"
				@close-modal="closeSchedulePropertyVisitModal"
				@send-message="sendScheduleVisitMessage"
			/>

			<PropertyNavigationBar
				v-show="shouldShowPropertyNavigationBar"
				:total-properties="properties?.length || 0"
				:active-property-index="activePropertyIndex"
				:is-viewing-gallery="isGalleryOpen"
				:should-show-get-in-touch-button="!!companyOrBrokerPhone"
				:accept-partnership-request-url="acceptPartnershipRequestUrl"
				:broker-name="brokerName"
				:broker-email="contactEmail"
				:active-property-commercial-id="activeProperty?.commercialId || null"
				:is-from-recommendation="isActivePropertyFromRecommendation"
				:show-schedule-visit-buttons="
					websiteConfig.globalSettings.enableVisitScheduling
				"
				@go-to-next-property="goToNextProperty"
				@go-to-previous-property="goToPreviousProperty"
				@send-get-in-touch-message="
					() => getInTouchWithBrokerUrlForProperty(activeProperty)
				"
				@open-schedule-property-visit-modal="
					() =>
						openScheduleVisitModalForProperty(
							activeProperty,
							isActivePropertyFromRecommendation
						)
				"
			/>
		</ClientOnly>

		<ImageCarousel
			v-if="activeProperty"
			v-show="showImagesCarouselModal"
			:key="`active-property-images-carousel:${activeProperty.id}`"
			:slides-per-view="1"
			:modules="['zoom', 'navigation']"
			:images="activePropertyImages"
			:has-videos-gallery="activeProperty.videos?.length > 0"
			:current-image-index="carouselModalImageIndex"
			variant="modal"
			@toggle-image-carousel-modal="toggleImageCarouselModal"
		/>

		<MediaGallery
			v-if="activeProperty && isGalleryOpen"
			class="modal-media-gallery"
			:images="activePropertyImages"
			:videos="activeProperty.videos"
			:active-tab="galleryActiveTab"
			@open-carousel="toggleImageCarouselModal(true, $event)"
			@close-gallery="closeGallery"
		/>

		<main class="relative flex-1 px-0 md:px-3 lg:px-0">
			<MinisiteExpiredModal
				v-if="!minisiteData || minisiteData.isExpired"
				:phone="companyOrBrokerPhone"
				@click-cta="handleExpirationModalButtonClick"
			/>

			<div v-else class="flex flex-col px-5 py-6 md:px-12 md:pb-0 md:pt-11">
				<header class="flex flex-col items-start justify-between lg:flex-row">
					<div class="flex flex-col gap-[2px] lg:gap-1 lg:pb-[30px]">
						<h1
							class="heading-font text-[24px] font-normal leading-[42px] text-[#000] lg:text-[32px] lg:leading-normal"
						>
							<template v-if="clientName">Olá, {{ clientName }}</template>
							<template v-else>Olá,</template>
						</h1>

						<span
							class="default-font text-base font-normal leading-[34px] text-[#000] lg:text-xl lg:leading-[36px]"
						>
							<template v-if="isPartnershipMinisite">
								Gostaria de propor parceria envolvendo
								{{ numberOfPropertiesText }}
							</template>
							<template v-else>
								Selecionei {{ numberOfPropertiesText }} para você
							</template>
						</span>
					</div>
					<span class="my-4 h-[2px] w-15 bg-[#000] lg:hidden"></span>

					<div
						class="mt-2 flex w-full gap-4 bg-[#f2f2f2] px-5 py-4 lg:mt-4 lg:w-auto"
					>
						<ClientOnly>
							<BrokerInformation
								:broker-name="brokerName"
								:whatsapp-message-url="contactMessageUrl"
							/>
						</ClientOnly>
					</div>
				</header>
				<span class="hidden h-[2px] w-15 bg-[#000] lg:block"></span>

				<section
					v-if="properties"
					class="grid w-full grid-cols-1 gap-8 py-6 lg:grid-cols-2 lg:pb-15 lg:pt-11"
				>
					<PropertyCard
						v-for="(property, index) in properties"
						:key="property.id"
						:property="property"
						variant="minisite"
						:should-show-get-in-touch-button="!!companyOrBrokerPhone"
						tag-background-color="#000"
						tag-text-color="#fff"
						:redirect-on-click="false"
						:accept-partnership-request-url="acceptPartnershipRequestUrl"
						:use-cta-directive="true"
						:show-schedule-visit-buttons="
							websiteConfig.globalSettings.enableVisitScheduling
						"
						@send-get-in-touch-message="
							() => getInTouchWithBrokerUrlForProperty(property)
						"
						@open-schedule-property-visit-modal="
							() => openScheduleVisitModalForProperty(property)
						"
						@open-gallery="() => openGalleryForProperty(property, 'photos')"
						@view-property="
							(property: Property, trigger: PropertyCardTrigger) => viewProperty(property, trigger, index)
						"
					/>
				</section>

				<section
					v-if="isPropertiesRecommendationEnabled"
					class="flex w-full flex-col gap-6 py-8 lg:gap-11 lg:py-15"
				>
					<div
						v-if="fetchPropertiesRecommendationStatus === 'error'"
						class="flex flex-col items-center justify-center gap-6"
					>
						<h2 class="default-text-color text-xl font-normal leading-none">
							Erro na solicitação
						</h2>

						<PButton
							color="black"
							class="w-[21.875rem] max-w-[80%] gap-1 font-medium"
							@click="retryFetchPropertiesRecommendation()"
						>
							<Icon name="ion:refresh" class="-mt-1 h-5 w-5 items-center" />
							Tentar novamente
						</PButton>
					</div>

					<template
						v-else-if="fetchPropertiesRecommendationStatus === 'success'"
					>
						<div
							v-if="recommendedProperties.length === 0"
							class="flex w-full flex-col items-center justify-center gap-4 lg:gap-7"
						>
							<span
								class="default-font text-center text-[28px] font-normal leading-8 text-[#000]"
							>
								Infelizmente, não conseguimos encontrar imóveis semelhantes
							</span>
							<span class="h-[2px] w-15 bg-[#000]"></span>
						</div>

						<div
							v-else
							class="flex w-full flex-col items-center justify-center gap-4 text-[24px] lg:gap-7 lg:text-[32px]"
						>
							<span
								class="heading-font text-center font-normal text-[#000] lg:hidden"
							>
								Propriedades similares
							</span>
							<span
								class="heading-font hidden text-center font-normal text-[#000] lg:block"
							>
								Outras opções de imóveis similares
							</span>
							<span class="h-[2px] w-15 bg-[#000]"></span>
						</div>

						<PropertyCarouselSection
							:properties="visibleRecommendedProperties"
							source="recommendation"
							variant="minisite"
							card-variant="minisite"
							:should-show-get-in-touch-button="!!companyOrBrokerPhone"
							:show-schedule-visit-buttons="
								websiteConfig.globalSettings.enableVisitScheduling
							"
							use-cta-directive
							@send-get-in-touch-message="getInTouchWithBrokerUrlForProperty"
							@open-schedule-property-visit-modal="(property: Property) =>
								openScheduleVisitModalForProperty(property, true)
							"
							@open-gallery="
								(property: Property) => openGalleryForProperty(property, 'photos', true)
							"
							@view-property="(property: Property, trigger: PropertyCardTrigger, index: number) => viewProperty(property, trigger, index, 'Recomendação')"
						/>
					</template>

					<div
						v-else
						class="flex w-full flex-col items-center justify-center gap-6"
					>
						<span
							class="heading-font text-center text-[28px] font-normal leading-8 text-[#000]"
						>
							Descubra imóveis semelhantes aos listados acima
						</span>

						<Icon
							v-if="fetchPropertiesRecommendationStatus === 'pending'"
							name="PilarLoadingSpinner"
							class="mx-auto my-0 h-11 w-11 animate-spin text-[#000]"
						/>

						<ButtonBlock
							v-else
							text="Ver outros imóveis semelhantes"
							@click="fetchPropertiesRecommendation"
						/>
					</div>
				</section>
			</div>

			<div v-if="minisiteData" v-show="false" class="container mx-auto"></div>
		</main>

		<template v-if="isPartnershipMinisite">
			<section class="mobile-partnership-section container">
				<h2 class="mobile-partnership-section-heading">
					Gostou de algum imóvel?
				</h2>

				<div class="mobile-partnership-section-divider"></div>

				<img
					:src="partnershipImage"
					alt="Pessoas apertando a mão em sinal de parceria, demonstrando que um acordo foi feito"
					class="mobile-partnership-section-image"
				/>

				<div class="mobile-partnership-section-text-wrapper">
					<h3 class="mobile-partnership-section-subheading">
						Faça conexões, construa oportunidades
					</h3>
					<p class="mobile-partnership-section-paragraph">
						Descubra imóveis que despertam seu interesse e selecione-os em nosso
						sistema. Essa pode ser uma porta de entrada para iniciar parcerias
						estratégicas e expandir suas oportunidades de negócios no mercado
						imobiliário.
					</p>
				</div>

				<ClientOnly>
					<AcceptPartnershipButton
						source="listing"
						:accept-request-url="acceptPartnershipRequestUrl!"
						:use-cta-directive="true"
						color="black"
					/>
				</ClientOnly>
			</section>

			<section class="partnership-section container">
				<div>
					<h2 class="partnership-section-heading">Gostou de algum imóvel?</h2>
					<span class="partnership-section-cta-text">
						Selecione no sistema seus favoritos para efetuar uma parceria
					</span>
				</div>

				<AcceptPartnershipButton
					source="listing"
					:accept-request-url="acceptPartnershipRequestUrl!"
					:use-cta-directive="true"
					color="black"
				/>
			</section>
		</template>

		<!-- TODO: vamos ter essa section no novo minisite? -->
		<section
			v-else-if="shouldShowCtaSection"
			class="mx-auto mb-8 mt-4 flex flex-col items-center md:relative md:mb-9 md:mt-[53px] md:flex-row"
		>
			<span
				class="default-text-color text-sm font-normal leading-[17px] md:absolute md:-left-[calc(100%+1.25rem)] md:top-1/2 md:-translate-y-1/2 md:translate-x-1/2 md:transform md:text-base md:leading-[19px]"
			>
				Gostou de nossos imóveis?
			</span>

			<ButtonBlock
				color="primary"
				:url="companyWebsiteUrlWithUtm"
				target="_blank"
				class="!md:px-5 !md:py-3 mt-3.5 !px-8 !py-4 font-normal !leading-none md:ml-5 md:mt-0"
				:data-rounded="websiteConfig.styles.appearance.defaultBorderRadius"
				@click="handleCtaButtonClick"
			>
				Encontre imóveis semelhantes
			</ButtonBlock>
		</section>

		<!-- TODO: vamos ter essa section no novo minisite? -->
		<!-- <Transition
			enter-active-class="transition-all duration-200 ease-out motion-reduce:transition-none"
			enter-from-class="translate-y-full opacity-0"
			enter-to-class="translate-y-0 opacity-1"
			leave-active-class="transition-all duration-200 ease-out motion-reduce:transition-none"
			leave-from-class="translate-y-0 opacity-1"
			leave-to-class="translate-y-full opacity-0"
		>
			<NuxtLink
				v-if="isSchedulingCtaEnabled"
				v-show="numberOfSelectedProperties > 0"
				:href="propertiesSelectionCtaWhatsappUrl"
				target="_blank"
				class="sticky bottom-6 mx-auto my-6 flex w-[19.375rem] items-center justify-center gap-5 rounded-full bg-whatsapp-brand-color px-3 py-5 text-base font-medium leading-[140%] text-white sm:w-[30.75rem] sm:px-[1.2rem] sm:py-5"
				@click="handlePropertiesSelectionCtaClick"
			>
				<Icon name="PilarWhatsapp" class="h-4 w-4" />
				Quero mais informações ({{ numberOfSelectedProperties }})
			</NuxtLink>
		</Transition> -->
	</div>
</template>

<script setup lang="ts">
import { computedEager, whenever } from '@vueuse/core';

import type { Property } from '@SHARED/core/entities/Property';
import type { WebsiteConfig } from '@SHARED/core/entities/WebsiteConfig';
import type { PropertyCardTrigger } from '@SHARED/components/molecules/PropertyCard.vue';
import type { CompanyWithoutWebsiteConfig } from '@SHARED/core/entities/Company';
import type { CarouselImage } from '@SHARED/components/molecules/ImageCarousel.vue';
import type { GalleryTab } from '@SHARED/components/molecules/MediaGallery.vue';
import type { PropertyDetailsProps } from '@SHARED/components/organisms/PropertyDetails.vue';

import { grammarInflection } from '@SHARED/utils';
import { useBodyScroll } from '@SHARED/composables/useBodyScroll';
import { useMediaGallery } from '@SHARED/composables/useMediaGallery';
import { usePropertyDetails } from '@SHARED/composables/usePropertyDetails';
import { concatUrlPath } from '@SHARED/utils/url';

import ButtonBlock from '@SHARED/components/blocks/ButtonBlock.vue';
import PropertyCard from '@SHARED/components/molecules/PropertyCard.vue';
import PropertyCarouselSection from '@SHARED/components/sections/PropertyCarouselSection.vue';
import SchedulePropertyVisitFormModal from '@SHARED/components/organisms/SchedulePropertyVisitFormModal.vue';
import ImageCarousel from '@SHARED/components/molecules/ImageCarousel.vue';
import ModalContainer from '@SHARED/components/molecules/ModalContainer.vue';
import PropertyDetails from '@SHARED/components/organisms/PropertyDetails.vue';
import MediaGallery from '@SHARED/components/molecules/MediaGallery.vue';
import AcceptPartnershipButton from '@SHARED/components/atoms/AcceptPartnershipButton.vue';
import BrokerInformation from '@SHARED/components/atoms/BrokerInformation.vue';

import { shareLinkTrigger } from '@/utils/enums';
import projectSetting from '@/package.json';
import { PropertiesRecommendationService } from '@/services/propertiesRecommendationService';
// import { PropertiesSelectionMessageService } from '@/services/propertiesSelectionMessage';
import { PropertyService } from '@/services/property';

import PropertyNavigationBar from '@/components/PropertyNavigationBar.vue';

import partnershipImage from '@/assets/images/parceria.jpg';
import CloseIcon from '~icons/mdi/close';

definePageMeta({
	name: 'PropertiesPage',
	alias: '/'
});

const route = useRoute();

const router = useRouter();

const { isExclusivesMinisite, isOpportunitiesMinisite, isRegularMinisite } =
	useMinisiteVariant();

const { enableScrollOnBody, disableScrollOnBody } = useBodyScroll();

const domain = useState<string>('domain');
const websiteConfig = useState<WebsiteConfig>('websiteConfig');
const company = useState<CompanyWithoutWebsiteConfig>('company');

const clientName = computed(() => {
	const queryStringName = route.query[queryStringParams.CLIENT_NAME] as
		| string
		| undefined;

	return queryStringName?.trim() || null;
});

function getMinisiteId(): string | null {
	const publicRuntimeConfig = useRuntimeConfig().public;
	const { exclusivesMinisiteId, opportunitiesMinisiteId } = publicRuntimeConfig;

	if (isExclusivesMinisite) return exclusivesMinisiteId;
	if (isOpportunitiesMinisite) return opportunitiesMinisiteId;

	return (route.params.minisiteId as string) || null;
}

type CompanyWebsiteUrlWithUtmParams = {
	properties?: Property[];
};

function getCompanyWebsiteUrlWithUtm({
	properties = []
}: CompanyWebsiteUrlWithUtmParams = {}): string {
	const redirectQueryString = new URLSearchParams({
		utm_medium: 'minisite',
		utm_source: company.value.name || 'null',
		utm_campaign:
			properties.map(property => property.commercialId).join(',') || 'null',
		utm_term: clientName.value || 'null'
	});

	const url = new URL(company.value.website);
	url.search = redirectQueryString.toString();

	return url.toString();
}

async function redirectToCompanyWebsite() {
	if (import.meta.env.DEV) {
		// eslint-disable-next-line no-console
		console.log(`redirect to ${getCompanyWebsiteUrlWithUtm()}`);
	} else {
		await navigateTo(
			{ path: getCompanyWebsiteUrlWithUtm() },
			{ external: true }
		);
	}
}

const { data: minisiteData } = await useAsyncData(
	`minisiteData:${domain.value}`,
	async () => {
		try {
			const minisiteId = getMinisiteId();

			if (!minisiteId) {
				await redirectToCompanyWebsite();
				return null;
			}

			if (
				(isExclusivesMinisite || isOpportunitiesMinisite) &&
				route.fullPath !== '/'
			) {
				await navigateTo(
					{
						path: '/',
						query: route.query
					},
					{ replace: true }
				);
			}

			const [minisiteDataResponse] = await $fetch(
				`/api/minisite-data/${minisiteId}`,
				{
					query: { minisiteDomain: domain.value }
				}
			);

			if (minisiteDataResponse) return minisiteDataResponse;

			return null;
		} catch {
			return null;
		}
	}
);

const properties = computed<Property[] | null>(() => {
	if (!minisiteData.value?.properties?.length) return null;

	return minisiteData.value.properties;
});

const allProperties = computed<Property[] | null>(() => {
	if (!properties.value?.length) return recommendedProperties.value;

	return properties.value.concat(recommendedProperties.value);
});

const isPartnershipMinisite = useState<boolean>(
	'isPartnershipMinisite',
	() => false
);

watchEffect(() => {
	if (minisiteData.value?.partnershipData && !minisiteData.value?.isExpired) {
		isPartnershipMinisite.value = true;
	}
});

const acceptPartnershipRequestUrl = computed<string | null>(
	() => minisiteData.value?.partnershipData?.shareRequestsUrl || null
);

const brokerName = computed<string | null>(() => {
	if (!minisiteData.value) return null;

	return (
		minisiteData.value.partnershipData?.requesterBrokerName ||
		minisiteData.value.brokerName ||
		null
	);
});

const propertyDetailsPartnershipRequestData = computed<
	PropertyDetailsProps['partnershipRequest']
>(() => {
	if (!isPartnershipMinisite || !acceptPartnershipRequestUrl.value) return null;

	return {
		brokerEmail: contactEmail.value || null,
		brokerName: brokerName.value,
		partnershipRequestUrl: acceptPartnershipRequestUrl.value
	};
});

const numberOfPropertiesLabelOptions = computedEager<[string, string]>(() => {
	if (isExclusivesMinisite) return ['exclusividade', 'exclusividades'];

	if (isOpportunitiesMinisite) return ['oportunidade', 'oportunidades'];

	return ['imóvel', 'imóveis'];
});

const activePropertyImages = computed<CarouselImage[]>(() => {
	if (!activeProperty.value) return [];

	return activeProperty.value.images.map((image, index) => ({
		url: image.watermarkUrl,
		caption: image.caption || `${index + 1}ª imagem do imóvel`
	}));
});

const numberOfPropertiesText = computed<string>(() => {
	const numberOfProperties = minisiteData.value?.properties.length || 0;

	const numberText = numberOfProperties || 'XX';

	const [singularLabel, pluralLabel] = numberOfPropertiesLabelOptions.value;

	const demonstrativePronoun = singularLabel === 'imóvel' ? 'este' : 'esta';

	return grammarInflection(
		numberOfProperties,
		`${demonstrativePronoun} ${singularLabel}`,
		`${demonstrativePronoun}s ${numberText} ${pluralLabel}`
	);
});

const isPropertiesRecommendationEnabled = computed(
	() => !!minisiteData.value?.featureFlags.isPropertiesRecommendationEnabled
);

whenever(
	minisiteData,
	({ properties }) => {
		const ogImage =
			PropertyService.getPropertyWatermarkUrlForOgImage(properties) ||
			websiteConfig.value.meta.image;

		const seoData = PropertyService.getPropertyDataForSeoTexts(
			properties,
			company.value.name
		);

		const title = seoData?.title || 'Seleção de imóveis';
		const description =
			seoData?.description ||
			'Selecionei esses imóveis especialmente para você.';

		useServerSeoMeta({
			ogImage,
			title,
			ogTitle: title,
			description,
			ogDescription: description
		});
	},
	{ immediate: true }
);

provide(MINISITE_DATA_PROVIDER, minisiteData.value);

const mixpanel = useMixpanel();

const hasSchedulePropertyVisitMessageBeenSent = ref<boolean>(false);
const shouldShowScheduleVisitModal = ref<boolean>(false);
const shouldShowPropertyDetailsModal = ref<boolean>(false);
const showImagesCarouselModal = ref<boolean>(false);
const carouselModalImageIndex = ref<number>(0);
const isTryingAgainToFetchPropertiesRecommendation = shallowRef<boolean>(false);

const {
	data: recommendedProperties,
	execute: fetchPropertiesRecommendation,
	status: fetchPropertiesRecommendationStatus
} = await useAsyncData(
	`properties:${domain.value}`,
	async () => {
		try {
			if (!isPropertiesRecommendationEnabled.value) return [];

			mixpanel.track(mixpanelEvents.REQUEST_PROPERTIES_RECOMMENDATION, {
				[mixpanelParams.RETRY]:
					isTryingAgainToFetchPropertiesRecommendation.value
			});

			const minisiteProperties = minisiteData.value?.properties || [];

			const allMinisitePropertiesMean =
				PropertiesRecommendationService.getPropertiesMean(minisiteProperties);

			const minisitePropertiesCommercialIds = minisiteProperties.map(
				property => property.commercialId
			);

			const [properties] = await $fetch('/api/properties-recommendation', {
				query: {
					minisiteDomain: domain.value,
					allMinisitePropertiesMean: JSON.stringify(allMinisitePropertiesMean),
					propertiesToIgnore: JSON.stringify(minisitePropertiesCommercialIds)
				}
			});

			if (properties) return properties;

			return [];
		} catch (error) {
			return [];
		}
	},
	{ default: () => [] }
);

async function retryFetchPropertiesRecommendation() {
	isTryingAgainToFetchPropertiesRecommendation.value = true;
	await fetchPropertiesRecommendation();
	isTryingAgainToFetchPropertiesRecommendation.value = false;
}

const numberOfRecommendedPropertiesToShow = ref(3);

const visibleRecommendedProperties = computed(() =>
	recommendedProperties.value.slice(
		0,
		numberOfRecommendedPropertiesToShow.value
	)
);

// const thereAreMorePropertiesToShow = computed(
// 	() =>
// 		numberOfRecommendedPropertiesToShow.value <
// 		recommendedProperties.value.length
// );

// function showMoreRecommendedProperties(): void {
// 	if (!thereAreMorePropertiesToShow.value) return;

// 	numberOfRecommendedPropertiesToShow.value += 3;

// 	const numberOfRecommendedProperties = recommendedProperties.value.length;

// 	mixpanel.track(mixpanelEvents.SHOW_MORE_RECOMMENDED_PROPERTIES, {
// 		[mixpanelParams.NUMBER_OF_RECOMMENDED_PROPERTIES]:
// 			numberOfRecommendedProperties,
// 		[mixpanelParams.NUMBER_OF_DISPLAYED_RECOMMENDED_PROPERTIES]: Math.min(
// 			numberOfRecommendedPropertiesToShow.value,
// 			numberOfRecommendedProperties
// 		)
// 	});
// }

function trackTabChange() {
	const isCurrentTabVisible = document.visibilityState === 'visible';

	if (isCurrentTabVisible) {
		mixpanel.track(mixpanelEvents.RETURN_TO_CURRENT_TAB);
	} else {
		mixpanel.track(mixpanelEvents.GO_TO_ANOTHER_TAB);
	}
}

function trackMouseLeaveDocument() {
	mixpanel.track(mixpanelEvents.MOUSE_LEAVE_DOCUMENT);
}

function trackMouseEnterDocument() {
	mixpanel.track(mixpanelEvents.MOUSE_ENTER_DOCUMENT);
}

function trackSelectedText() {
	if (process.server) return;

	const text = window.getSelection()?.toString().trim();

	if (!text) return;

	mixpanel.track(mixpanelEvents.SELECT_TEXT, {
		[mixpanelParams.COPIED_TEXT]: text
	});
}

const shouldShowCtaSection = computed(
	() =>
		!isPropertiesRecommendationEnabled.value &&
		isRegularMinisite &&
		!minisiteData.value?.isExpired &&
		minisiteData.value?.properties.length
);

onMounted(() => {
	mixpanel.init();

	const numberOfProperties = minisiteData.value?.properties.length || 0;

	const featureFlagsList = minisiteData.value?.originalFeatureFlagsList || [];

	mixpanel.register({
		[mixpanelParams.MINISITE_ID]: getMinisiteId(),
		[mixpanelParams.CLIENT_NAME]: clientName.value,
		[mixpanelParams.DOMAIN]: domain.value,
		[mixpanelParams.COMPANY_NAME]: company.value.name,
		[mixpanelParams.NUMBER_OF_PROPERTIES]: numberOfProperties,
		[mixpanelParams.CONTACT_PHONE]: minisiteData.value?.phone || null,
		[mixpanelParams.VERSION]: projectSetting.version,
		[mixpanelParams.FEATURE_FLAGS_LIST]: featureFlagsList
	});

	mixpanel.track(mixpanelEvents.ENTER_MINISITE);

	if (minisiteData.value?.isExpired) {
		mixpanel.track(mixpanelEvents.MINISITE_EXPIRED);
	}

	window.addEventListener('mouseup', trackSelectedText);
	window.addEventListener('visibilitychange', trackTabChange);
	window.document.addEventListener('mouseleave', trackMouseLeaveDocument);
	window.document.addEventListener('mouseenter', trackMouseEnterDocument);
});

const companyWebsiteUrlWithUtm = computed(() =>
	getCompanyWebsiteUrlWithUtm({ properties: minisiteData.value?.properties })
);

type MinisitePropertyCardTrigger =
	| PropertyCardTrigger
	| 'browser-navigation'
	| 'modal-navigation';

const propertyCardOpeningTriggerText: Record<
	MinisitePropertyCardTrigger,
	string
> = {
	'card-body': 'Corpo do card',
	button: 'Botão',
	'browser-navigation': 'Navegação do navegador',
	'modal-navigation': 'Navegação pelas setas do modal'
};

const contactMessageUrl = computed<string | null>(() => {
	if (!companyOrBrokerPhone.value) return null;

	const minisiteUrl = concatUrlPath(
		domain.value,
		router.currentRoute.value.fullPath
	);

	const baseUrl = `https://api.whatsapp.com/send?phone=${companyOrBrokerPhone.value}&text=`;
	const message = encodeURI(
		'Olá, gostaria de mais informações sobre os imóveis do site '
	);

	return `${baseUrl}${message}${minisiteUrl}`;
});

const contactEmail = computed<string | null>(
	() => company.value?.email || null
);

// const selectedProperties = ref<Map<Property['id'], Property>>(new Map());

// const numberOfSelectedProperties = computed<number>(
// 	() => selectedProperties.value.size
// );

// function isPropertySelected(property: Property): boolean {
// 	return selectedProperties.value.has(property.id);
// }

// function addPropertyToSelectionList(property: Property) {
// 	selectedProperties.value.set(property.id, property);
// }

// function removePropertyFromSelectionList(property: Property) {
// 	selectedProperties.value.delete(property.id);
// }

// function handlePropertyCardSelectionButtonClick({
// 	property,
// 	isRecommendationProperty
// }: PropertyCardSelectionButtonClickEventParams) {
// 	const shouldRemovePropertyFromSelectionList = isPropertySelected(property);

// 	if (shouldRemovePropertyFromSelectionList) {
// 		removePropertyFromSelectionList(property);
// 	} else {
// 		addPropertyToSelectionList(property);
// 	}

// 	const actionType = shouldRemovePropertyFromSelectionList
// 		? mixpanelParams.PROPERTY_WAS_REMOVED_FROM_SELECTION_LIST
// 		: mixpanelParams.PROPERTY_WAS_ADDED_TO_SELECTION_LIST;

// 	const recommendationRelatedParams = isRecommendationProperty
// 		? { [mixpanelParams.IS_A_RECOMMENDED_PROPERTY]: true }
// 		: {};

// 	mixpanel.track(mixpanelEvents.CLICK_ON_PROPERTY_CARD_SELECTION_BUTTON, {
// 		[mixpanelParams.PROPERTY_COMMERCIAL_ID]: property.commercialId,
// 		[mixpanelParams.NUMBER_OF_PROPERTIES_ON_SELECTION_LIST]:
// 			numberOfSelectedProperties.value,
// 		[mixpanelParams.NUMBER_OF_PROPERTIES]:
// 			minisiteData.value?.properties.length || null,
// 		[mixpanelParams.TYPE]: actionType,
// 		...recommendationRelatedParams
// 	});
// }

// const propertiesSelectionCtaWhatsappUrl = computed<string>(() =>
// 	PropertiesSelectionMessageService.getWhatsappUrl({
// 		properties: Array.from(selectedProperties.value.values()),
// 		phone: minisiteData.value?.phone || ''
// 	})
// );

// function handlePropertiesSelectionCtaClick() {
// 	const propertiesCommercialIds = Array.from(
// 		selectedProperties.value.values()
// 	).map(property => property.commercialId);

// 	const numberOfRecommendedPropertiesOnSelectionList =
// 		recommendedProperties.value.filter(property =>
// 			propertiesCommercialIds.includes(property.commercialId)
// 		).length;

// 	mixpanel.track(mixpanelEvents.CLICK_ON_PROPERTIES_SELECTION_CTA, {
// 		[mixpanelParams.COMMERCIAL_IDS_FROM_PROPERTIES_ON_SELECTION_LIST]:
// 			propertiesCommercialIds,
// 		[mixpanelParams.NUMBER_OF_PROPERTIES]:
// 			minisiteData.value?.properties.length || null,
// 		[mixpanelParams.NUMBER_OF_RECOMMENDED_PROPERTIES]:
// 			recommendedProperties.value.length,
// 		[mixpanelParams.NUMBER_OF_RECOMMENDED_PROPERTIES_ON_SELECTION_LIST]:
// 			numberOfRecommendedPropertiesOnSelectionList
// 	});
// }

const companyOrBrokerPhone = computed<string | null>(
	() => minisiteData.value?.phone || company.value?.phone || null
);

function handleExpirationModalButtonClick() {
	mixpanel.track(mixpanelEvents.CLICK_EXPIRATION_MODAL_BUTTON);
}

function handleCtaButtonClick() {
	mixpanel.track(mixpanelEvents.CLICK_CTA_BUTTON);
}

function getPropertyIndexFromQueryParams(): number | null {
	if (!allProperties.value) return null;

	const propertyCommercialId = route.query?.imovel;

	if (!propertyCommercialId) return null;

	const propertyIndex = allProperties.value.findIndex(
		property => property.commercialId === propertyCommercialId.toString()
	);

	if (propertyIndex === -1) return null;

	return propertyIndex;
}

const activePropertyCommercialId = computed<string | null>(() => {
	const propertyCommercialId = route.query?.imovel;

	if (!propertyCommercialId) return null;

	return propertyCommercialId.toString();
});

const activePropertyIndex = computed<number | null>(() =>
	getPropertyIndexFromQueryParams()
);

const activeProperty = computed<Property | null>(() => {
	if (!allProperties.value || !activePropertyCommercialId.value) return null;

	const propertyIndex = allProperties.value.findIndex(
		property => property.commercialId === activePropertyCommercialId.value
	);

	if (propertyIndex === -1) return null;

	return allProperties.value[propertyIndex];
});

const isActivePropertyFromRecommendation = shallowRef(false);

watch(activeProperty, activeProperty => {
	if (activeProperty) {
		if (shouldShowPropertyDetailsModal.value) {
			shouldShowScheduleVisitModal.value = false;
			isGalleryOpen.value = false;
		}

		return;
	}

	shouldShowPropertyDetailsModal.value = false;
	shouldShowScheduleVisitModal.value = false;
	isGalleryOpen.value = false;

	enableScrollOnBody();
});

async function setActiveProperty(
	{ commercialId }: Property,
	isFromRecommendation: boolean = false
) {
	isActivePropertyFromRecommendation.value = isFromRecommendation;

	await router.push({
		query: {
			...route.query,
			imovel: commercialId
		}
	});
}

function trackShareButtonClick() {
	mixpanel.track(mixpanelEvents.SHARE_MINISITE, {
		[mixpanelParams.PROPERTY_COMMERCIAL_ID]: activeProperty.value?.commercialId,
		[mixpanelParams.SHARE_CLICK]: shareLinkTrigger['expanded-property-card']
	});
}

const { propertyPricingText, propertyInfos, propertyTitle, propertyThumbnail } =
	usePropertyDetails(activeProperty);

function getRecommendationParams(commercialId: string): { [key: string]: any } {
	const isRecommendedProperty = recommendedProperties.value.some(
		recommendedProperty => recommendedProperty.commercialId === commercialId
	);

	if (isRecommendedProperty) {
		return {
			[mixpanelParams.IS_A_RECOMMENDED_PROPERTY]: true
		};
	}

	return {};
}

async function closePropertyDetailsModal() {
	const propertyCommercialId = route.query.imovel;

	const recommendationParams = getRecommendationParams(
		propertyCommercialId as string
	);

	mixpanel.track(mixpanelEvents.CLOSE_PROPERTY_CARD, {
		[mixpanelParams.PROPERTY_COMMERCIAL_ID]: propertyCommercialId,
		...recommendationParams
	});

	shouldShowPropertyDetailsModal.value = false;

	await router.push({
		query: {
			...route.query,
			imovel: undefined
		}
	});
}

function toggleImageCarouselModal(state: boolean, index: number = 0) {
	showImagesCarouselModal.value = state;
	carouselModalImageIndex.value = index;

	if (!state || !activeProperty.value) return;

	mixpanel.track(mixpanelEvents.OPEN_CAROUSEL_MODAL, {
		[mixpanelParams.PROPERTY_COMMERCIAL_ID]: activeProperty.value.commercialId,
		[mixpanelParams.OPEN_IMAGE_URL]:
			activeProperty.value.images[index].watermarkUrl
	});
}

async function viewProperty(
	property: Property,
	trigger: MinisitePropertyCardTrigger,
	index?: number,
	source: 'Minisite' | 'Recomendação' = 'Minisite'
) {
	const recommendationParams = getRecommendationParams(property.commercialId);

	mixpanel.track(mixpanelEvents.EXPAND_PROPERTY_CARD, {
		[mixpanelParams.PROPERTY_COMMERCIAL_ID]: property.commercialId,
		[mixpanelParams.PROPERTY_POSITION]:
			typeof index === 'number' ? index + 1 : null,
		[mixpanelParams.PROPERTY_SOURCE]: source,
		[mixpanelParams.PROPERTY_CARD_OPENING_TRIGGER]:
			propertyCardOpeningTriggerText[trigger],
		...recommendationParams
	});

	const isFromRecommendation = source === 'Recomendação';

	await setActiveProperty(property, isFromRecommendation);
	shouldShowPropertyDetailsModal.value = true;
	disableScrollOnBody();
}

function openSchedulePropertyVisitModal(property: Property | null) {
	shouldShowScheduleVisitModal.value = true;
	disableScrollOnBody();

	const { commercialId } = property || {};
	const propertyIndex = minisiteData.value?.properties.findIndex(
		prop => prop.commercialId === commercialId
	);

	mixpanel.track(mixpanelEvents.OPEN_SCHEDULE_VISIT_MODAL, {
		[mixpanelParams.PROPERTY_POSITION]: (propertyIndex || 0) + 1,
		[mixpanelParams.PROPERTY_COMMERCIAL_ID]: commercialId || 'Não informado'
	});
}

function closeSchedulePropertyVisitModal() {
	shouldShowScheduleVisitModal.value = false;
	enableScrollOnBody();
}

const { isGalleryOpen, galleryActiveTab, openGallery, closeGallery } =
	useMediaGallery();

const shouldShowPropertyNavigationBar = computed<boolean>(
	() => shouldShowPropertyDetailsModal.value || isGalleryOpen.value
);

async function openGalleryForProperty(
	property: Property | null,
	context: GalleryTab,
	isFromRecommendation: boolean = false
) {
	if (!property) return;

	await setActiveProperty(property, isFromRecommendation);
	openGallery(context);
}

async function openScheduleVisitModalForProperty(
	property: Property | null,
	isFromRecommendation: boolean = false
) {
	if (!property) return;

	await setActiveProperty(property, isFromRecommendation);
	openSchedulePropertyVisitModal(property);
}

function goToNextProperty() {
	if (activePropertyIndex.value === null || !properties.value) return;

	const nextIndex = activePropertyIndex.value + 1;

	if (nextIndex > properties.value.length) return;

	viewProperty(properties.value[nextIndex], 'modal-navigation', nextIndex);
}

function goToPreviousProperty() {
	if (activePropertyIndex.value === null || !properties.value) return;

	const previousIndex = activePropertyIndex.value - 1;

	if (previousIndex < 0) return;

	viewProperty(
		properties.value[previousIndex],
		'modal-navigation',
		previousIndex
	);
}

watch(shouldShowPropertyDetailsModal, () => {
	if (!shouldShowPropertyDetailsModal.value) enableScrollOnBody();
});

function sendScheduleVisitMessage(message: string) {
	if (!minisiteData.value?.phone) return;

	const url = `https://api.whatsapp.com/send?phone=${
		minisiteData.value.phone
	}&text=${encodeURI(message)}`;

	window.open(url, '_blank');

	hasSchedulePropertyVisitMessageBeenSent.value = true;
}

function getInTouchWithBrokerUrlForProperty(property: Property | null) {
	if (!companyOrBrokerPhone.value || !property) return null;

	const url = concatUrlPath(
		domain.value,
		`${route.fullPath}?imovel=${property.commercialId}`
	);
	const baseUrl = `https://api.whatsapp.com/send?phone=${companyOrBrokerPhone.value}&text=`;
	const message = encodeURI(
		`Olá, gostaria de mais informações sobre o imóvel ${property.commercialId} `
	);

	const text = `${message}%0a%0a${url}`;

	window.open(`${baseUrl}${text}`, '_blank');
}

onMounted(() => {
	const propertyIndex = getPropertyIndexFromQueryParams();

	if (propertyIndex === null || !allProperties.value) return;

	viewProperty(
		allProperties.value[propertyIndex],
		'browser-navigation',
		propertyIndex
	);
});
</script>

<style lang="scss" scoped>
.page-wrapper {
	width: 100%;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

.modal-property-view-wrapper {
	z-index: 20;
}

.modal-property-view {
	height: calc(100% - 5rem);

	@include screen-up(md) {
		height: calc(100% - 5.5rem);
	}

	@include screen-up(lg) {
		height: calc(100% - 3rem - 5.5rem);
	}

	background: var(--white);
}

.modal-media-gallery {
	height: 100%;
	padding-bottom: 5rem;
}

.close-property-view-button {
	position: absolute;
	top: 1rem;
	right: 1rem;
	z-index: 50;
	padding: 0.5rem;
	background-color: var(--white);
	color: var(--black);
	transition: all 200ms ease-in-out;
	border: solid 1px #e5e5e5;
	box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15);

	@include border-radius-size();

	svg {
		width: 1.75rem;
		height: 1.75rem;
	}

	&:hover {
		background-color: var(--lightgray);
	}

	@include screen-up(lg) {
		top: 1.5rem;
		right: 1.5rem;

		svg {
			width: 2rem;
			height: 2rem;
		}
	}
}

.schedule-property-view-modal {
	z-index: 50;
}

.partnership-section {
	position: sticky;
	bottom: 0;
	z-index: 10;

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	gap: 1rem;

	background-color: #f2f2f2;
	color: #000;

	margin: 2.25rem auto 0 auto;
	padding: 1.5rem 3rem;

	@include screen-down(md) {
		display: none !important;
	}
}

.partnership-section-heading {
	font-size: 1.125rem;
	font-weight: 500;
	line-height: 1.5;
}

.partnership-section-cta-text {
	display: none;
	font-size: 1rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5;

	@include screen-up(md) {
		display: block;
	}
}

.mobile-partnership-section {
	display: flex;
	flex-direction: column;

	padding-top: 2.5rem;
	padding-bottom: 2.75rem;

	border-top: 1px solid #e5e5e5;

	&-heading {
		color: var(--black, #000);
		text-align: center;
		font-family: var(--heading-font);
		font-size: 1.75rem;
		font-style: normal;
		font-weight: 400;
		line-height: 1.5;
	}

	&-divider {
		width: 3.75rem;
		height: 0.175rem;
		background: var(--black, #000);
		margin: 1rem auto 1.5rem auto;
	}

	&-image {
		width: 100%;
		margin-bottom: 1.25rem;
	}

	&-text-wrapper {
		display: flex;
		flex-direction: column;
		gap: 0.75rem;
		margin-bottom: 1.5rem;
	}

	&-subheading {
		color: var(--black, #000);

		font-family: var(--default-font);
		font-size: 1.25rem;
		font-style: normal;
		font-weight: 400;
		line-height: 1.5;
	}

	&-paragraph {
		color: var(--black, #000);

		font-family: var(--default-font);
		font-size: 1rem;
		font-style: normal;
		font-weight: 400;
		line-height: 1.5;
	}

	@include screen-up(md) {
		display: none !important;
	}
}
</style>
