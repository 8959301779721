import type { Property } from '@SHARED/core/entities/Property';
import type { PresenterLabel } from '@SHARED/presenters/LabelPresenter';
import { PropertyPresenter } from '@SHARED/presenters/PropertyPresenter';

type UsePropertyDetailsReturn = {
	propertyPricingText: ComputedRef<{ label: string; value: string }[]>;
	propertyInfos: ComputedRef<PresenterLabel[]>;
	propertyThumbnail: ComputedRef<string | null>;
	propertyTitle: ComputedRef<string>;
	propertyAddress: ComputedRef<string>;
	propertyPricingInfos: ComputedRef<PresenterLabel[]>;
};

export function usePropertyDetails(
	property: Ref<Property | null> | ComputedRef<Property | null>
): UsePropertyDetailsReturn {
	const propertyPricingText = computed<{ label: string; value: string }[]>(
		() => {
			if (!property.value) return [];

			if (property.value.ad.shouldHidePrices) {
				return [
					{
						label: 'Não informado',
						value: 'Valor sob consulta'
					}
				];
			}

			const propertyPricing = [];

			if (property.value.askingPrice) {
				propertyPricing.push({
					label: 'Venda',
					value: PropertyPresenter.getCurrencyInfoText(
						property.value.askingPrice
					)
				});
			}

			if (property.value.rentPrice) {
				propertyPricing.push({
					label: 'Aluguel',
					value: `${PropertyPresenter.getCurrencyInfoText(property.value.rentPrice)}/mês`
				});
			}

			return propertyPricing;
		}
	);

	const propertyInfos = computed<PresenterLabel[]>(() => {
		if (!property.value) return [];

		return PropertyPresenter.getItemsInformation(
			['area', 'totalArea', 'bedrooms', 'suites', 'bathrooms', 'parkingSpots'],
			property.value
		);
	});

	const propertyThumbnail = computed<string | null>(() => {
		if (property.value?.thumbnailUrl) return property.value.thumbnailUrl;

		const firstImage = property.value?.images?.[0]?.watermarkUrl;

		if (firstImage) return firstImage;

		return null;
	});

	const propertyAddress = computed<string>(() =>
		property.value ? PropertyPresenter.getAddressText(property.value) : ''
	);

	const propertyTitle = computed<string>(
		() => property.value?.ad?.title || propertyAddress.value
	);

	const propertyPricingInfos = computed<PresenterLabel[]>(() => {
		if (!property.value) return [];

		return PropertyPresenter.getItemsInformation(
			['condoFee', 'tax', 'pricePerSquareMeter'],
			property.value
		);
	});

	return {
		propertyPricingText,
		propertyInfos,
		propertyThumbnail,
		propertyTitle,
		propertyAddress,
		propertyPricingInfos
	};
}
