<template>
	<form @submit.prevent="handleFormSubmission">
		<ModalContainer
			variant="default"
			:sizes="modalSizes"
			@close-modal="closeAndResetForm"
		>
			<ModalContent
				title="Agendar visita"
				has-scrollable-body
				@close-modal="closeAndResetForm"
			>
				<template #body>
					<div
						v-if="hasFormBeenSubmitted || hasMessageBeenSend"
						class="success-message-wrapper"
					>
						<CheckIcon class="success-message-icon" />
						<span class="default-font success-message-title">
							Solicitação de agendamento enviada com sucesso!
						</span>
						<span class="default-font success-message-description">
							Vamos verificar a disponibilidade das datas e entraremos em
							contato para confirmar seu agendamento
						</span>
					</div>

					<div v-else class="form-content-wrapper">
						<div class="property-preview">
							<img
								:src="thumbnail"
								alt="Imagem do imóvel"
								class="property-preview-thumbnail"
							/>
							<div class="property-preview-content">
								<span class="property-title default-font">
									{{ propertyTitle }}
								</span>
								<div
									v-for="pricing in propertyPrice"
									:key="`property-preview-pricing:${pricing.label}:${propertyCommercialId}`"
									class="property-pricing-item"
								>
									<span class="pricing-value default-font">
										{{ pricing.value }}
									</span>
								</div>
								<div
									v-if="propertyInformation.length > 0"
									class="default-font property-info-container"
								>
									<span
										v-for="{ value, label } in propertyInformation"
										:key="`property-preview-info:${propertyCommercialId}:${label}`"
										class="property-info-value default-font"
									>
										{{ value }} {{ label }}
									</span>
								</div>
							</div>
						</div>

						<div class="date-time-input-container">
							<FormField
								label="Data"
								label-class="campaign-label"
								:error-message="vuelidate.date.$errors[0]?.$message"
							>
								<template #default="{ id }">
									<DateInput
										:id="id"
										v-model="formData.date"
										@change="vuelidate.date.$touch"
									/>
								</template>
							</FormField>
							<FormField
								label="Hora"
								label-class="campaign-label"
								:error-message="vuelidate.time.$errors[0]?.$message"
							>
								<template #default="{ id }">
									<TimeInput
										:id="id"
										v-model="formData.time"
										type="time"
										class="campaign-input default-font"
										@change="vuelidate.time.$touch"
									/>
								</template>
							</FormField>
						</div>
						<span class="date-time-information default-font">
							Escolha a data e hora preferidas para a visita. O corretor
							confirmará a disponibilidade e entrará em contato
						</span>

						<FormField
							label="Seu nome"
							autocomplete="name"
							label-class="campaign-label"
							:error-message="vuelidate.name.$errors[0]?.$message"
						>
							<template #default="{ id }">
								<InputField
									:id="id"
									v-model="formData.name"
									:value="formData.name"
									class="campaign-input default-font"
									@change="vuelidate.name.$touch"
								/>
							</template>
						</FormField>

						<FormField
							label="Telefone"
							autocomplete="phone"
							label-class="campaign-label"
							:error-message="vuelidate.phone.$errors[0]?.$message"
						>
							<template #default="{ id }">
								<PhoneInput
									:id="id"
									v-model="formData.phone"
									class="phone-input-container"
									input-class="campaign-input-gray default-font"
									select-class="campaign-phone-select default-font"
									@update:mask="updateMask"
									@change="vuelidate.phone.$touch"
								/>
							</template>
						</FormField>

						<FormField
							label="E-mail"
							autocomplete="email"
							label-class="campaign-label"
							:error-message="vuelidate.email.$errors[0]?.$message"
						>
							<template #default="{ id }">
								<InputField
									:id="id"
									v-model="formData.email"
									class="campaign-input default-font"
									@change="vuelidate.email.$touch"
								/>
							</template>
						</FormField>

						<RecaptchaMessage class="recaptcha-message default-font" />
					</div>
				</template>

				<template #footer>
					<ButtonBlock
						v-if="hasFormBeenSubmitted || hasMessageBeenSend"
						text="Ok, entendi"
						tag="button"
						:is-outlined="false"
						class="submit-form-button"
						color="white"
						type="button"
						@click="closeAndResetForm"
					/>
					<ButtonBlock
						v-else
						tag="button"
						:is-outlined="false"
						class="submit-form-button"
						color="white"
						gap="0.75rem"
						type="submit"
						:disabled="isSubmittingForm"
					>
						<template #before>
							<LoadingIcon v-show="isSubmittingForm" class="loading" />
							<CheckIcon v-show="!isSubmittingForm" />

							<span class="submit-form-button-text-mobile">
								Enviar solicitação
							</span>
							<span class="submit-form-button-text-desktop">
								Enviar solicitação de agendamento
							</span>
						</template>
					</ButtonBlock>
				</template>
			</ModalContent>
		</ModalContainer>
		<div ref="recaptchaRoot"></div>
	</form>
</template>

<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core';
import {
	email as isValidEmail,
	helpers,
	required
} from '@vuelidate/validators';

import type { Phone } from '@SHARED/components/molecules/PhoneInput.vue';
import type { RegisterNewDealParams } from '@SHARED/core/ports/services/DealRegistrationService';
import type { Property } from '@SHARED/core/entities/Property';
import type { ResponsiveSizeOrAuto } from '@SHARED/utils/helperTypes';
import type { PresenterLabel } from '@SHARED/presenters/LabelPresenter';

import { PLACEHOLDER_IMAGE_URL } from '@SHARED/presenters/LabelPresenter';
import { concatUrlPath } from '@SHARED/utils/url';
import {
	isFutureDate,
	isCurrentDate,
	isValidDate,
	isValidTimeString
} from '@SHARED/utils/date';
import { PHONE_SERVICE } from '@SHARED/utils/vueProvidersSymbols';
import { copyObject } from '@SHARED/utils';
import { usePhoneInputValidation } from '@SHARED/composables/usePhoneInputValidation';
import { useNotyf } from '@SHARED/composables/useNotyf';
import {
	useFormWithRecaptcha,
	type FormSubmitionFunctionParams
} from '@SHARED/composables/useFormWithRecaptcha';

import RecaptchaMessage from '@SHARED/components/molecules/RecaptchaMessage.vue';
import ModalContainer from '@SHARED/components/molecules/ModalContainer.vue';
import ModalContent from '@SHARED/components/molecules/ModalContent.vue';
import FormField from '@SHARED/components/molecules/FormField.vue';
import InputField from '@SHARED/components/atoms/InputField.vue';
import DateInput from '@SHARED/components/atoms/DateInput.vue';
import TimeInput from '@SHARED/components/atoms/TimeInput.vue';
import PhoneInput from '@SHARED/components/molecules/PhoneInput.vue';

import ButtonBlock from '@SHARED/components/blocks/ButtonBlock.vue';

import CheckIcon from '~icons/mdi/check';
import LoadingIcon from '~icons/mdi/loading';

defineOptions({ name: 'SchedulePropertyVisitFormModal' });

type SchedulePropertyVisitFormModalProps = {
	propertyCommercialId: Property['commercialId'];
	propertyPrice: { label: string; value: string }[];
	propertyInformation: PresenterLabel[];
	propertyTitle: Property['ad']['title'];
	propertyThumbnail: string | null;
	isMinisiteContext?: boolean;
	hasMessageBeenSent?: boolean;
};

const props = withDefaults(defineProps<SchedulePropertyVisitFormModalProps>(), {
	isMinisiteContext: false,
	hasMessageBeenSent: false
});

export type PropertyVisitFormData = {
	name: string;
	date: string;
	time: string;
	email: string;
	phone: Phone;
};

const formInitialState: PropertyVisitFormData = {
	name: '',
	date: '',
	time: '',
	email: '',
	phone: {
		number: '',
		countryCode: '+55'
	}
};

type Emits = {
	(e: 'send-message', message: string): void;
	(e: 'update:hasMessageBeenSent', value: boolean): void;
	(e: 'close-modal'): void;
};

const emit = defineEmits<Emits>();

const phoneService = inject(PHONE_SERVICE)!;

const domain = useState<string>('domain');

const hasFormBeenSubmitted = ref<boolean>(false);
const hasMessageBeenSend = defineModel<boolean>('hasMessageBeenSent');

const formData = reactive<PropertyVisitFormData>(copyObject(formInitialState));

const modalSizes: {
	width: ResponsiveSizeOrAuto;
	height: ResponsiveSizeOrAuto;
} = {
	width: {
		desktop: '640px',
		tablet: '640px',
		mobile: '100%'
	},
	height: {
		desktop: 'auto',
		tablet: 'auto',
		mobile: '100dvh'
	}
};

const { updateMask, isValidPhone } = usePhoneInputValidation(
	() => formData.phone.number
);

function isCurrentOrFutureDate(value: string): boolean {
	return isCurrentDate(value) || isFutureDate(value);
}

const validationRules = computed(() => ({
	name: {
		required: helpers.withMessage('Este campo é obrigatório', required)
	},
	date: {
		required: helpers.withMessage('Este campo é obrigatório', required),
		isValidDate: helpers.withMessage('Data inválida', isValidDate),
		isFutureOrCurrentDate: helpers.withMessage(
			'Selecione hoje ou uma data futura',
			isCurrentOrFutureDate
		)
	},
	time: {
		required: helpers.withMessage('Este campo é obrigatório', required),
		time: helpers.withMessage('Hora inválida', isValidTimeString)
	},
	email: {
		required: helpers.withMessage('Este campo é obrigatório', required),
		email: helpers.withMessage('E-mail inválido', isValidEmail)
	},
	phone: {
		required: helpers.withMessage('Este campo é obrigatório', required),
		phone: helpers.withMessage('Telefone inválido', isValidPhone)
	}
}));

const vuelidate = useVuelidate(validationRules, formData);

const { isSubmittingForm, recaptchaRoot, submitForm, resetForm } =
	useFormWithRecaptcha({
		vuelidate,
		formInitialState,
		formData,
		onError: handleFormSubmitionError,
		formSubmitionFunction: submitFormData,
		shouldSubmitForm: () => !!props.propertyCommercialId
	});

const route = useRoute();

function handleFormSubmission() {
	if (!props.isMinisiteContext) {
		submitForm();
		return;
	}

	if (vuelidate.value.$error) return;

	const date = formData.date.replace(/-/g, '/');
	const dateMessage = date ? `para ${date}` : '';
	const timeMessage = formData.time ? `às ${formData.time}` : '';
	const dateAndTimeMessage = `${dateMessage} ${timeMessage}`.trim();

	const message = `Olá, posso agendar uma visita ao imóvel ${props.propertyCommercialId}${dateAndTimeMessage ? ' ' + dateAndTimeMessage : ''}? Qual é a disponibilidade na sua agenda?`;

	const url = concatUrlPath(domain.value, route.fullPath);

	emit('send-message', `${message} %0a%0a${url}`);

	resetForm();
}

const thumbnail = computed<string>(
	() => props.propertyThumbnail || PLACEHOLDER_IMAGE_URL
);

async function submitFormData({ recaptchaToken }: FormSubmitionFunctionParams) {
	const phoneNumber = phoneService.getPhoneValue(formData.phone.number);

	const scheduledPropertyVisitFormData: RegisterNewDealParams = {
		title: `Agendar visita - ${formData.name}`,
		name: formData.name,
		email: formData.email,
		phone: formData.phone.countryCode + phoneNumber,
		message: `Horário escolhido para visita: ${formData.date} ${formData.time}`,
		companyDomain: domain.value,
		formName: `Agendamento de visita - ${props.propertyCommercialId}`,
		reference: props.propertyCommercialId,
		campaignName: null
	};

	await $fetch('/api/contact-form', {
		method: 'POST',
		body: {
			captchaToken: recaptchaToken,
			dealData: scheduledPropertyVisitFormData
		},
		headers: { host: domain.value }
	});

	hasFormBeenSubmitted.value = true;
}

function handleFormSubmitionError() {
	const notyf = useNotyf();
	notyf.error('Erro ao enviar formulário');
}

function closeAndResetForm() {
	emit('close-modal');

	if (hasFormBeenSubmitted.value) {
		hasFormBeenSubmitted.value = false;
	}

	if (hasMessageBeenSend.value) {
		hasMessageBeenSend.value = false;
	}

	resetForm();
}
</script>

<style lang="scss" scoped>
.submit-form-button {
	fill: var(--white);

	&-text-mobile {
		display: block;

		@include screen-up(md) {
			display: none;
		}
	}

	&-text-desktop {
		display: none;

		@include screen-up(md) {
			display: block;
		}
	}
}

.cancel-button {
	font-weight: 600;
}

.campaign-input {
	width: 100%;
	border: 1px solid #ccc;
	font-size: 1rem;
	font-weight: 400;
	padding: 0.75rem;
	line-height: 100%;
	color: #666;

	&.date-picker-input::-webkit-inner-spin-button,
	&.date-picker-input::-webkit-calendar-picker-indicator {
		display: none;
		appearance: none;
		-webkit-appearance: none;
	}
}

input[type='time'],
input[type='date'] {
	&::-webkit-calendar-picker-indicator {
		padding: 0;
		margin-left: 0.75rem;
		background: transparent;
		color: transparent;
		cursor: pointer;
		height: auto;
		position: absolute;
		left: 0;
		width: 20px;
		height: 20px;
	}
}

.recaptcha-message {
	margin-top: 0.75rem;
	color: var(--darkgray);
}

.property-preview {
	display: flex;
	gap: 0.75rem;
	border: 0px solid var(--lightgray);
	padding-bottom: 1.5rem;
	border-bottom-width: 1px;

	.property-preview-thumbnail {
		width: 5.5rem;
		height: auto;
		object-fit: cover;
	}

	.property-preview-content {
		display: flex;
		justify-content: center;
		flex-direction: column;
		gap: 0.5rem;

		.property-title {
			font-size: 0.875rem;
			color: var(--black);
			font-weight: 500;
			line-height: 150%;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.property-pricing-item {
			display: flex;
			flex-wrap: wrap;
			gap: 0.5rem;

			.pricing-value {
				color: var(--black);
				line-height: 100%;
				font-size: 0.875rem;
				font-weight: 400;
			}
		}

		.property-info-container {
			display: flex;
			flex-wrap: wrap;
			align-items: center;

			.property-info-value {
				font-size: 0.75rem;
				font-weight: 400;
				line-height: 100%;
				color: var(--darkgray);

				&:not(:last-child) {
					&::after {
						content: '';
						display: inline-block;
						margin-left: 0.5rem;
						margin-right: 0.5rem;
						width: 1px;
						height: 0.75rem;
						background-color: var(--gray);
					}
				}
			}
		}
	}
}

.form-field-container {
	margin-top: 1.5rem;

	@include screen-up(lg) {
		margin-top: 1.25rem;
	}
}

.date-time-input-container {
	display: flex;
	flex-direction: column;
	gap: 1rem;

	@include screen-up(md) {
		flex-direction: row;

		> .form-field-container {
			width: 50%;
		}
	}
}

.date-time-information {
	font-size: 0.875rem;
	font-weight: 400;
	line-height: 1.25rem;
	color: #666;
	margin-top: 0.5rem;
}

.success-message-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 1rem;
	padding-top: 1rem;
	padding-bottom: 2rem;

	.success-message-icon {
		width: 4rem;
		height: 4rem;
		fill: var(--black);
	}

	.success-message-title {
		font-size: 1.375rem;
		font-weight: 400;
		fill: var(--black);
		text-align: center;
	}

	.success-message-description {
		font-size: 1rem;
		font-weight: 400;
		color: var(--darkgray);
		text-align: center;
	}
}

.form-content-wrapper {
	display: flex;
	flex-direction: column;
}
</style>
