<template>
	<main v-if="property" class="property-details-wrapper">
		<KollerImageCarousel
			v-if="isKollerVariant"
			:images="carouselImages"
			:has-videos-gallery="property.videos?.length > 0"
			@open-gallery="emit('open-image-gallery')"
			@open-video-gallery="emit('open-video-gallery')"
			@toggle-image-carousel-modal="toggleModalCarousel"
		/>

		<ImageCarousel
			v-else
			:key="`property-details-image-carousel:${property.id}`"
			:images="carouselImages"
			variant="default"
			:has-videos-gallery="property.videos?.length > 0"
			:slides-per-view="mainCarouselSlidesPerView || undefined"
			:carousel-height="mainCarouselHeight || undefined"
			@open-gallery="emit('open-image-gallery')"
			@open-video-gallery="emit('open-video-gallery')"
			@toggle-image-carousel-modal="toggleImageGallery"
		/>

		<div class="property-details container section-padding">
			<div class="property-details-heading" :data-variant="variant">
				<div v-if="variant !== 'koller'" class="property-pricing-container">
					<div
						v-for="pricing in propertyPricingText"
						:key="`property-pricing:${pricing.label}:${property.id}`"
						class="property-pricing-item"
					>
						<span class="pricing-value default-font">
							{{ pricing.value }}
						</span>
						<span class="pricing-label">
							{{ pricing.label }}
						</span>
					</div>
				</div>

				<div class="property-title-container" :data-variant="variant">
					<h1 class="details-title heading-font align-text-center">
						{{ propertyTitle }}
					</h1>

					<div
						v-if="propertyInfos.length > 0"
						class="default-font property-info-container"
					>
						<span
							v-for="{ value, label } in propertyInfos"
							:key="`property-info:${property.id}:${label}`"
							class="property-info-value"
						>
							{{ value }} {{ label }}
						</span>
					</div>
				</div>

				<div v-if="isKollerVariant" class="koller-pricing-container">
					<div
						v-for="pricing in propertyPricingText"
						:key="`property-pricing:${pricing.label}:${property.id}`"
						class="property-pricing-item"
					>
						<span class="pricing-value default-font">
							{{ pricing.value }}
						</span>
						<span class="pricing-label">
							{{ pricing.label }}
						</span>
					</div>
				</div>

				<section class="info-and-cta-section" :data-variant="variant">
					<div
						class="cta-buttons-container"
						:data-variant="partnershipRequest ? 'partnership-request' : null"
					>
						<template v-if="partnershipRequest">
							<BrokerInformation
								:broker-name="partnershipRequest.brokerName"
								class="requester-broker-information"
							/>

							<AcceptPartnershipButton
								source="expanded-property-card"
								:accept-request-url="partnershipRequest.partnershipRequestUrl"
								color="black"
							/>
						</template>

						<template v-else>
							<ButtonBlock
								v-if="getInTouchUrl"
								class="property-contact-button"
								text="Entrar em contato"
								background-color="secondary"
								font-family="default"
								:url="getInTouchUrl"
								is-outlined
							>
								<template #before>
									<MessageIcon />
								</template>
							</ButtonBlock>
							<ButtonBlock
								v-if="getInTouchUrl && showScheduleVisitButtons"
								class="property-contact-button"
								text="Agendar visita"
								background-color="primary"
								font-family="default"
								:is-outlined="false"
								@click.self="emit('open-schedule-property-visit-modal')"
							>
								<template #before>
									<CalendarIcon />
								</template>
							</ButtonBlock>
						</template>
					</div>

					<button
						class="copy-button default-font copy-commercial-id"
						aria-label="Copiar código do imóvel"
						@click="copyCommercialId(property.commercialId)"
					>
						Código do Imóvel:
						<span class="property-commercial-id-text">
							{{ property.commercialId }}
						</span>
						<CopyToClipboardIcon />
						<div
							class="tooltip"
							:class="{ visible: showCopiedCommercialCodeTooltip }"
						>
							<span class="default-font">Código copiado!</span>
						</div>
					</button>
				</section>
			</div>

			<DetailsPageSection
				v-if="property.ad.description"
				title="Descrição"
				:content="property.ad.description"
				:data-variant="variant"
			/>
			<DetailsPageSection
				:title="isKollerVariant ? 'Seus Vizinhos' : 'Endereço'"
				:content="propertyAddress"
				:data-variant="variant"
			/>

			<DetailsPageSection
				v-if="propertyPricingInfos.length"
				title="Detalhes"
				:data-variant="variant"
			>
				<div
					v-if="propertyPricingInfos.length > 0"
					class="default-font property-info-container property-pricing-info-container"
				>
					<span
						v-for="{ value, label } in propertyPricingInfos"
						:key="`property-pricing-info:${property.id}:${label}`"
						class="property-info-value"
					>
						{{ label }}:
						<span class="property-pricing-value">
							{{ value }}
						</span>
					</span>
				</div>
			</DetailsPageSection>

			<DetailsPageSection title="Compartilhar" :data-variant="variant">
				<SharingContent
					:mail-subject="mailSubject"
					:content-to-share="shareUrl"
					@click-link="emit('click-share-link')"
				>
					<button
						class="copy-button share-icon-link"
						aria-label="Copiar URL"
						@click="copyShareUrl(shareUrl)"
					>
						<LinkIcon />
						<div
							class="tooltip"
							:class="{ visible: showCopiedShareUrlTooltip }"
						>
							<span class="default-font">Link copiado!</span>
						</div>
					</button>
				</SharingContent>
			</DetailsPageSection>

			<!-- FEATURES -->
			<section
				v-if="!isEmpty(featuresByCategory)"
				class="features-section"
				:data-variant="variant"
			>
				<h2 class="details-title heading-font">Características</h2>
				<span class="separator"></span>
				<FeaturesByCategory
					:features-by-category="property.featuresByCategory"
					:data-variant="variant"
				/>
			</section>
		</div>

		<!-- SIMILAR PROPERTIES -->
		<section
			v-if="similarProperties?.length"
			class="similar-properties-section"
		>
			<h2 v-if="!isKollerVariant" class="details-title heading-font container">
				Propriedades Similares
			</h2>
			<span class="separator"></span>
			<PropertyCarouselSection
				v-bind="similarPropertiesCarouselConfig"
				:page-key="String(route.params.propertySlug)"
				:show-redirection-button="false"
				:property-slug="String(route.params.propertySlug)"
				:properties="similarProperties"
				:show-schedule-visit-buttons="showScheduleVisitButtons"
			/>
		</section>

		<PaddingContainer v-if="shouldShowBreadcrumbSection">
			<PropertyBreadcrumb :property="property" />
			<div class="related-property-data-divider"></div>
		</PaddingContainer>

		<PaddingContainer v-if="shouldShowPropertiesRelatedDataSection">
			<RelatedPropertyDataSection
				:actual-region="property!.region"
				:nearby-regions="propertiesRelatedData!.nearbyRegions"
				:available-property-types="
					propertiesRelatedData!.availablePropertyTypes
				"
				class="related-property-data-section"
			/>
		</PaddingContainer>
	</main>
</template>

<script setup lang="ts">
import { useClipboard } from '@vueuse/core';

import type { Property } from '@SHARED/core/entities/Property';
import type { PropertyCarouselSection as IPropertyCarouselSection } from '@SHARED/core/entities/sections/PropertyCarouselSection';
import type { GetRelatedPropertiesDataInPropertyRegionUseCaseResult as PropertiesRelatedData } from '@SHARED/core/useCases/getRelatedPropertiesDataInPropertyRegionUseCase';
import type { PropertyPageVariant } from '@SHARED/core/entities/pages/PropertyPage';
import type { CssSize } from '@SHARED/utils/helperTypes';
import type { CarouselImage } from '@SHARED/components/molecules/ImageCarousel.vue';
import type { PropertySource } from '@SHARED/core/entities/sections/PropertyListingSection';
import type { GlobalWebsiteSettings } from '@SHARED/core/entities/WebsiteConfig';

import { isEmpty } from '@SHARED/utils';

import { usePropertyDetails } from '@SHARED/composables/usePropertyDetails';

import PaddingContainer from '@SHARED/components/molecules/PaddingContainer.vue';
import ButtonBlock from '@SHARED/components/blocks/ButtonBlock.vue';
import PropertyCarouselSection from '@SHARED/components/sections/PropertyCarouselSection.vue';
import DetailsPageSection from '@SHARED/components/organisms/DetailsPageSection.vue';
import FeaturesByCategory from '@SHARED/components/organisms/FeaturesByCategory.vue';
import SharingContent from '@SHARED/components/atoms/SharingContent.vue';
import ImageCarousel from '@SHARED/components/molecules/ImageCarousel.vue';
import KollerImageCarousel from '@SHARED/components/molecules/KollerImageCarousel.vue';
import RelatedPropertyDataSection from '@SHARED/components/molecules/RelatedPropertyDataSection.vue';
import AcceptPartnershipButton from '@SHARED/components/atoms/AcceptPartnershipButton.vue';
import PropertyBreadcrumb from '@SHARED/components/molecules/PropertyBreadcrumb.vue';
import BrokerInformation from '@SHARED/components/atoms/BrokerInformation.vue';

import MessageIcon from '~icons/mdi/message-text-outline';
import CalendarIcon from '~icons/mdi/calendar-outline';
import CopyToClipboardIcon from '~icons/mdi/content-copy';
import LinkIcon from '~icons/mdi/link-variant';

defineOptions({ name: 'PropertyDetails' });

type PartnershipRequestData = {
	brokerName: string | null;
	brokerEmail: string | null;
	partnershipRequestUrl: string;
};

export type PropertyDetailsProps = {
	property: Property | null;
	similarProperties?: Property[] | null;
	similarPropertiesCarouselConfig?: IPropertyCarouselSection['config'] | null;
	variant: PropertyPageVariant;
	mainCarouselSlidesPerView?: number | null;
	mainCarouselHeight?: {
		mobile: CssSize | null;
		desktop: CssSize | null;
	} | null;
	getInTouchUrl: string | null;
	propertiesRelatedData?: PropertiesRelatedData | null;
	partnershipRequest?: PartnershipRequestData | null;
	showBreadcrumbs?: boolean;
	showScheduleVisitButtons?: boolean;
	source?: PropertySource;
	useCtaDirective?: boolean;
};

type Emits = {
	(e: 'open-video-gallery'): void;
	(e: 'open-image-gallery'): void;
	(e: 'toggle-image-carousel-modal', state: boolean, index: number): void;
	(e: 'open-schedule-property-visit-modal'): void;
	(e: 'click-share-link'): void;
};

const props = withDefaults(defineProps<PropertyDetailsProps>(), {
	property: null,
	similarProperties: null,
	similarPropertiesCarouselConfig: null,
	variant: 'default',
	mainCarouselSlidesPerView: null,
	mainCarouselHeight: null,
	propertiesRelatedData: null,
	partnershipRequest: null,
	showBreadcrumbs: false,
	showScheduleVisitButtons: true,
	source: 'base',
	useCtaDirective: false
});

const emit = defineEmits<Emits>();

const route = useRoute();

const domain = useState<string>('domain');

const globalSettings = useState<GlobalWebsiteSettings>('globalSettings');

const activePropertyCommercialId = useState<null | string>(
	'activePropertyCommercialId'
);

const shareUrl = computed(() => domain.value + route.fullPath);

const isKollerVariant = computed<boolean>(() => props.variant === 'koller');

const property = computed<Property | null>(() => props.property);

const {
	propertyPricingText,
	propertyInfos,
	propertyTitle,
	propertyAddress,
	propertyPricingInfos
} = usePropertyDetails(property);

const { copy: copyCommercialId, copied: showCopiedCommercialCodeTooltip } =
	useClipboard({
		copiedDuring: 2500
	});

const { copy: copyShareUrl, copied: showCopiedShareUrlTooltip } = useClipboard({
	copiedDuring: 2500
});

const mailSubject = computed<string>(
	() => `Leia mais sobre o imóvel ${props.property?.commercialId}`
);

const featuresByCategory = computed<Property['featuresByCategory']>(
	() => props.property?.featuresByCategory || {}
);

const shouldShowBreadcrumbSection = computed<boolean>(
	() => props.showBreadcrumbs && globalSettings.value.enableBreadcrumb
);

const shouldShowPropertiesRelatedDataSection = computed<boolean>(() => {
	if (!props.property || !props.propertiesRelatedData) return false;

	const { availablePropertyTypes, nearbyRegions } = props.propertiesRelatedData;

	return availablePropertyTypes.length > 0 || nearbyRegions.length > 0;
});

const carouselImages = computed<CarouselImage[]>(() => {
	if (!props.property) return [];

	return props.property.images.map((image, index) => ({
		url: image.watermarkUrl,
		caption: image.caption || `${index + 1}ª imagem do imóvel`
	}));
});

function toggleModalCarousel(state: boolean, index: number = 0) {
	emit('toggle-image-carousel-modal', state, index);
}

function toggleImageGallery(state: boolean) {
	if (state) {
		emit('open-image-gallery');
	}
}

onBeforeUnmount(() => {
	activePropertyCommercialId.value = null;
});
</script>

<style lang="scss" scoped>
@import '@SHARED/assets/style/mixins/scrollbar.scss';

.loading-section {
	height: calc(100vh - var(--header-height));
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 1.5rem;

	.loading {
		width: 4rem;
		height: 4rem;
	}

	.loading-text {
		color: var(--black);
		text-align: center;
		font-family: var(--default-font);
		font-size: 1.5rem;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
}

.details-title {
	text-transform: uppercase;
	text-align: left;
	font-size: 1.75rem;

	@include screen-up(lg) {
		text-align: center;
		font-size: 2.5rem;
	}
}

.align-text-center {
	text-align: center;
}

.features-section,
.similar-properties-section {
	padding-top: 2rem;
	padding-bottom: 2rem;
}

.property-details-wrapper {
	overflow-y: scroll;
	height: 100%;

	@include scrollbar-styling($trackOpacity: 1);
}

.property-details {
	display: flex;
	flex-direction: column;
	width: 100%;
	background-color: var(--white);

	.property-details-heading {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 1rem;
		padding-top: 3rem;
		padding-bottom: 3rem;

		&[data-variant='koller'] {
			gap: 0;
			justify-content: flex-start;
			align-items: flex-start;
			padding-top: 0.75rem;
			padding-bottom: 0.75rem;

			@include screen-up(lg) {
				padding-top: 2.5rem;
				padding-bottom: 2.5rem;
			}
		}

		.property-pricing-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
			gap: 1.5rem;
			width: 100%;
			padding-bottom: 2rem;
			margin-bottom: 0.5rem;

			border-bottom-width: 1px;
			border-color: rgba(0, 0, 0, 0.25);

			@include screen-up(lg) {
				flex-direction: row;
				align-items: center;
				justify-content: center;
				gap: 2.5rem;
			}

			.property-pricing-item {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				gap: 0.5rem;

				@include screen-up(lg) {
					align-items: center;
					justify-content: center;
				}

				.pricing-value {
					line-height: 100%;
					font-size: 1.5rem;

					@include screen-up(lg) {
						font-size: 2rem;
					}
				}

				.pricing-label {
					font-size: 1rem;
					font-weight: 300;
					line-height: 100%;
				}
			}
		}
	}

	.property-info-container {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;

		.property-info-value {
			min-width: max-content;
			display: flex;
			align-items: center;

			.property-pricing-value {
				font-weight: 500;
				margin-left: 0.35rem;
			}

			&:not(:first-child) {
				&::before {
					content: '';
					display: inline-block;
					margin-left: 0.75rem;
					margin-right: 0.75rem;
					width: 4px;
					height: 4px;
					background-color: var(--gray);
				}
			}
		}
	}

	.property-contact-button {
		font-weight: 500;
		margin-top: 1rem;
		margin-bottom: 0.25rem;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 0.75rem;
	}

	.property-pricing-info-container {
		justify-content: flex-start;
	}

	.features-section {
		display: flex;
		flex-direction: column;
		padding-top: 2rem;
		padding-bottom: 2rem;

		@include screen-up(lg) {
			align-items: center;
		}

		&[data-variant='koller'] {
			padding-top: 2.5rem;
			padding-bottom: 0;
			border: 0px solid var(--lightgray);
			border-top-width: 1px;

			.separator {
				display: none;
			}

			.details-title {
				font-weight: 400;
				font-style: italic;
				font-size: 2rem;

				@include screen-up(lg) {
					font-size: 2.5rem;
				}
			}

			@include screen-up(lg) {
				padding-top: 3rem;

				.separator {
					display: flex;
				}
			}
		}
	}
}

.copy-button.copy-commercial-id {
	font-size: 0.875rem;
	font-weight: 400;
	color: var(--darkgray);
	text-align: center;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	gap: 0.25rem;

	.tooltip {
		left: 50%;
		transform: translate(-50%, -125%);

		&::after {
			left: 50%;
			top: 100%;
			transform: translateX(-50%) rotate(-90deg);
			border-color: transparent var(--black) transparent transparent;
		}
	}

	@include screen-up(lg) {
		.tooltip {
			text-align: center;
			left: 100%;
			transform: translate(-0.75rem, -125%);

			&::after {
				transform: translateX(0) rotate(-90deg);
				left: 0;
			}
		}
	}

	.property-commercial-id-text {
		font-weight: 500;
		margin-right: 0.25rem;
		color: var(--black);
	}
}

.copy-button {
	position: relative;

	.tooltip {
		display: none;
		transition: opacity 0.1s ease-in-out;
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0.75rem;
		padding: 0.5rem 1rem;
		white-space: nowrap;
		align-items: center;
		justify-content: center;

		transform: translateY(-125%);
		background: var(--black);
		color: var(--white);
		border-radius: 0.25rem;
		border-bottom-left-radius: 0px;

		span {
			font-size: 0.75rem;
		}

		&::after {
			content: '';
			position: absolute;
			top: 100%;
			left: 0px;
			border-width: 0.25rem;
			border-style: solid;
			border-color: var(--black) transparent transparent transparent;
		}

		&.visible {
			display: flex;
			opacity: 1;
		}
	}
}

.koller-pricing-container {
	display: flex;
	align-items: center;
	width: 100%;
	gap: 3rem;

	.property-pricing-item {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		gap: 0.25rem;
		padding-top: 2rem;
		padding-bottom: 1.5rem;

		@include screen-up(lg) {
			padding-top: 1.5rem;
		}

		.pricing-value {
			line-height: 100%;
			font-size: 1.25rem;
			font-weight: 500;

			@include screen-up(lg) {
				font-weight: 400;
				font-size: 1.5rem;
			}
		}

		.pricing-label {
			font-size: 1rem;
			font-weight: 300;
			line-height: 100%;
		}
	}
}

.property-title-container {
	display: flex;
	width: 100%;
	gap: inherit;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	&[data-variant='koller'] {
		gap: 1rem;
		padding-top: 2rem;
		padding-bottom: 2rem;
		border: 0px solid var(--lightgray);
		border-top-width: 1px;
		border-bottom-width: 1px;
		align-items: flex-start;

		@include screen-up(lg) {
			gap: 0.5rem;
			padding-top: 1.5rem;
			padding-bottom: 1.5rem;
			border-top-width: 0px;
		}

		.property-info-container {
			justify-content: flex-start;
			color: var(--darkgray);
		}

		.details-title {
			font-weight: 600;
			font-size: 2rem;
			line-height: 150%;
			text-align: left;

			@include screen-up(lg) {
				font-size: 2.5rem;
			}
		}
	}
}

.info-and-cta-section {
	display: flex;
	width: 100%;
	gap: inherit;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.cta-buttons-container {
		display: flex;
		flex-direction: column;
		gap: 0.75rem;
		width: 100%;

		&[data-variant='partnership-request'] {
			padding-top: 1.5rem;
			border-top-width: 1px;
			border-color: rgba(0, 0, 0, 0.25);
			gap: 1.5rem;

			@include screen-up(md) {
				padding-top: 0.5rem;
				border-top-width: 0px;
			}
		}

		@include screen-up(lg) {
			width: auto;
			flex-direction: row;
			gap: 1.25rem;
		}
	}

	.requester-broker-information {
		@include screen-up(md) {
			display: none;
		}
	}

	&[data-variant='koller'] {
		flex-direction: column;
		align-items: center;
		gap: 0.75rem;
		padding-bottom: 2.5rem;
		border: 0px solid var(--lightgray);
		border-bottom-width: 1px;

		.cta-buttons-container {
			gap: 0.75rem;
		}

		@include screen-up(lg) {
			padding-bottom: 3rem;
			flex-direction: row;
			justify-content: space-between;
		}

		.property-contact-button {
			width: 100%;
			font-weight: 600;
			margin: 0;
			padding: 0.75rem 1.25rem;

			@include screen-up(lg) {
				width: auto;
			}
		}
	}
}

.related-property-data-divider {
	display: flex;
	height: 0.0625rem;
	flex-direction: column;
	align-items: flex-start;
	align-self: stretch;
	background-color: var(--lightgray);
	margin: 1.25rem 0;
}

.related-property-data-section {
	margin-bottom: 5rem;
}
</style>
