<template>
	<div class="koller-image-carousel-wrapper">
		<Swiper
			:slides-per-view="1"
			:space-between="0"
			class="custom-swiper"
			:modules="[Thumbs]"
			:thumbs="{ swiper: thumbsSwiper }"
		>
			<SwiperSlide
				v-for="(image, index) in images"
				:key="`property-image:${image.url}`"
				class="carousel-slide-container"
				:class="carouselItemClass"
				@click="$emit('toggle-image-carousel-modal', true, index)"
			>
				<NuxtImg
					:alt="image.caption"
					width="100%"
					:placeholder="DEFAULT_NUXT_IMAGE_PLACEHOLDER"
					loading="lazy"
					:src="image.url"
				/>
			</SwiperSlide>
		</Swiper>

		<div class="koller-carousel-navigation">
			<Swiper
				:modules="[Thumbs]"
				:space-between="10"
				:slides-per-view="3.5"
				watch-slides-progress
				:style="{
					height: '100%',
					'padding-right': '200px',
					position: 'relative'
				}"
				@swiper="setThumbsSwiper"
			>
				<CarouselButtons
					:data-variant="'koller'"
					:has-videos-gallery="hasVideosGallery"
					@open-gallery="emit('open-gallery')"
					@open-video-gallery="emit('open-video-gallery')"
				/>
				<SwiperSlide
					v-for="image in images"
					:key="`property-image:${image.url}`"
					:style="{ height: '100%' }"
				>
					<NuxtImg
						:alt="image.caption"
						width="100%"
						sizes="300px md:200px"
						placeholder
						loading="lazy"
						:src="image.url"
					/>
				</SwiperSlide>
			</Swiper>
		</div>
	</div>
</template>

<script setup lang="ts">
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

import type { CarouselImage } from '@SHARED/components/molecules/ImageCarousel.vue';

import { Swiper, SwiperSlide } from 'swiper/vue';
import { Thumbs } from 'swiper/modules';

import { DEFAULT_NUXT_IMAGE_PLACEHOLDER } from '@SHARED/utils/style';
import CarouselButtons from '@SHARED/components/molecules/CarouselButtons.vue';

defineOptions({ name: 'KollerImageCarousel' });

type KollerImageCarousel = {
	images: CarouselImage[];
	hasVideosGallery?: boolean;
};

withDefaults(defineProps<KollerImageCarousel>(), {
	hasVideosGallery: false
});

type Emits = {
	(e: 'open-gallery'): void;
	(e: 'open-video-gallery'): void;
	(e: 'toggle-image-carousel-modal', state: boolean, index?: number): void;
};

const emit = defineEmits<Emits>();

const thumbsSwiper = ref(null);
const setThumbsSwiper = (swiper: any) => {
	thumbsSwiper.value = swiper;
};
</script>

<style lang="scss" scoped>
$navigationActiveSlideOutlineWidth: 0.25rem;
$desktopNavigationSlideHeight: 10rem;
$desktopNavigationSlideWidth: 18.125rem;
$desktopNavigationPadding: 2rem;
$desktopNavigationContainerHeight: calc(
	$desktopNavigationSlideHeight + $desktopNavigationPadding
);

$mobileNavigationSlideHeight: 5.625rem;
$mobileNavigationSlideWidth: 8.75rem;
$mobileNavigationPadding: 0.75rem;
$mobileNavigationButtonsHeight: calc(
	4.125rem + calc($mobileNavigationPadding * 2)
);
$mobileNavigationContainerHeight: calc(
	calc($mobileNavigationSlideHeight + $mobileNavigationPadding) +
		$mobileNavigationButtonsHeight
);

.koller-image-carousel-wrapper {
	position: relative;
	height: calc(100vh - var(--header-height));
	width: 100%;

	.carousel-slide-container {
		width: 100%;
		height: calc(
			calc(100vh - $mobileNavigationContainerHeight) - var(--header-height)
		);

		@include screen-up(lg) {
			height: calc(
				calc(100vh - $desktopNavigationContainerHeight) - var(--header-height)
			);
		}

		img {
			transform: scale(1.01);
			transition: all 400ms cubic-bezier(0.4, 0, 0.2, 1);
			position: absolute;
			z-index: 1;
			width: 100%;
			object-position: center;
			object-fit: cover;
			height: inherit;
		}

		&:hover img {
			transform: scale(1.1);
			filter: brightness(80%);
		}
	}

	.carousel-slide-container {
		position: relative;
		overflow: hidden;
		cursor: pointer;
	}
}

.koller-carousel-navigation {
	background-color: var(--white);
	position: relative;
	width: 100%;
	gap: $mobileNavigationPadding;
	height: $mobileNavigationContainerHeight;
	min-height: $mobileNavigationContainerHeight;
	padding: $mobileNavigationPadding;

	@include screen-up(lg) {
		width: 100%;
		gap: $desktopNavigationPadding;
		height: $desktopNavigationContainerHeight;
		min-height: $desktopNavigationContainerHeight;
		padding: $desktopNavigationPadding;
	}

	img {
		object-fit: cover;
		object-position: center;
		width: 100%;
		height: 100%;
	}
}

.swiper-slide-thumb-active {
	outline: $navigationActiveSlideOutlineWidth solid var(--black);
	outline-offset: -$navigationActiveSlideOutlineWidth;
}

.swiper-wrapper {
	height: 50px;
}
</style>
