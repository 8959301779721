<template>
	<div class="gallery-wrapper">
		<div
			ref="galleryElement"
			class="grid-wrapper"
			:style="{ 'margin-top': animationMarginTop }"
		>
			<div class="gallery-navigation" :class="animationClasses">
				<div class="gallery-tabs-navigation-container">
					<button
						v-for="tab in navigationTabs"
						:key="tab.value"
						class="tab-navigation-item"
						:class="{ active: activeTab === tab.value }"
						@click="activeTab = tab.value"
					>
						{{ tab.label }}
					</button>
				</div>
				<div class="grid-buttons-container">
					<ShareIcon class="gallery-icon" @click="toggleShareContent" />
					<!-- TODO: melhorar nome dos métodos, classes e variáveis (`share-content-dropdown` ou `share-content-list` etc + variações nos métodos e variáveis)-->
					<div v-show="showShareContent" class="share-content">
						<SharingContent
							variant="dropdown"
							mail-subject="Leia mais sobre este imóvel"
							show-labels
							:content-to-share="shareUrl"
						/>
					</div>
					<CloseIcon
						class="gallery-icon close-icon"
						@click="emit('close-gallery')"
					/>
				</div>
			</div>
			<div v-show="activeTab === 'photos'" class="media-gallery-container">
				<button
					v-for="(image, index) in images"
					:key="index"
					class="gallery-item"
					aria-label="Abrir carrossel"
					@click="() => emit('open-carousel', index)"
				>
					<NuxtImg
						:src="image.url"
						:alt="image.caption"
						width="100%"
						:placeholder="DEFAULT_NUXT_IMAGE_PLACEHOLDER"
						loading="lazy"
					/>
				</button>
			</div>
			<div
				v-show="activeTab === 'videos'"
				class="media-gallery-container video-gallery"
			>
				<iframe
					v-for="videoUrl in videos"
					:key="`video:${mediaContextId}:${videoUrl}`"
					:src="videoUrl"
					class="video-iframe gallery-item"
				>
				</iframe>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { onKeyStroke, useScroll } from '@vueuse/core';

import type { CssSize } from '@SHARED/utils/helperTypes';

import {
	getCssNumberValueInPixels,
	DEFAULT_NUXT_IMAGE_PLACEHOLDER
} from '@SHARED/utils/style';
import { concatUrlPath } from '@SHARED/utils/url';
import { useAnimation } from '@SHARED/composables/useAnimation';
import { useId } from '@SHARED/composables/useId';

import SharingContent from '@SHARED/components/atoms/SharingContent.vue';
import CloseIcon from '~icons/ant-design/close-outlined';
import ShareIcon from '~icons/material-symbols/share';

defineOptions({ name: 'MediaGallery' });

export type GalleryTab = 'photos' | 'videos';

export type MediaGalleryImage = {
	url: string;
	caption: string;
};

type MediaGalleryProps = {
	images: MediaGalleryImage[];
	videos: string[];
	activeTab?: GalleryTab;
	// location: {
	//   latitude: number;
	//   longitude: number;
	// }
};

type Emits = {
	(e: 'close-gallery'): void;
	(e: 'open-carousel', index: number): void;
};

const emit = defineEmits<Emits>();

const props = withDefaults(defineProps<MediaGalleryProps>(), {
	activeTab: 'photos'
});

const mediaContextId = useId('media');

onKeyStroke('Escape', () => emit('close-gallery'));

const router = useRouter();

const showShareContent = ref<boolean>(false);

const domain = useState<string>('domain');

const shareUrl = computed<string>(() =>
	concatUrlPath(domain.value, router.currentRoute.value.fullPath)
);

function toggleShareContent(): void {
	showShareContent.value = !showShareContent.value;
}

const navigationHeight = '6rem';

const galleryElement = ref<HTMLElement | null>(null);

const { y: yPosition } = useScroll(galleryElement);

const animationMarginTop = ref<CssSize>(navigationHeight);

const { animationClasses, getAnimationClass } = useAnimation({
	animationStyle: 'slide-down',
	animationStatus: 'end'
});

type NavigationTab = {
	label: string;
	value: GalleryTab;
};

const navigationTabs = computed<NavigationTab[]>(() => {
	const tabs: NavigationTab[] = [];

	if (props.images?.length) {
		tabs.push({ label: 'Fotos', value: 'photos' });
	}

	if (props.videos?.length) {
		tabs.push({ label: 'Vídeos', value: 'videos' });
	}

	// if (!isEmpty(props.location)) {
	// 	tabs.push({ label: 'Street View', value: 'street-view' });
	// }

	return tabs;
});

const activeTab = ref<GalleryTab>(props.activeTab);

watch(yPosition, (newPosition, oldPosition) => {
	if (!oldPosition || newPosition < oldPosition) {
		animationClasses.value = getAnimationClass('end');
		animationMarginTop.value = navigationHeight;
		return;
	}

	if (newPosition < getCssNumberValueInPixels(navigationHeight)) return;

	animationClasses.value = getAnimationClass('start');
	animationMarginTop.value = '0';
});
</script>

<style lang="scss" scoped>
@import '@SHARED/assets/style/mixins/scrollbar.scss';

@mixin gallery-navigation-item {
	background-color: rgba(255, 255, 255, 0.1);
	padding: 0.25rem;

	&:hover {
		background-color: rgba(255, 255, 255, 0.25);
	}
}

.media-gallery-container {
	background-color: var(--black);
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 1rem;

	&.video-gallery {
		grid-template-columns: 1fr;

		@include screen-down(md) {
			.gallery-item {
				grid-column: span 2;
			}
		}

		@include screen-up(md) {
			grid-template-columns: repeat(2, 1fr);
		}
	}

	@include screen-up(lg) {
		padding: 1rem 6rem 4rem 6rem;
	}

	.gallery-item {
		grid-column: span 1;

		&:nth-child(3n) {
			grid-column: span 2;
		}

		img {
			width: 100%;
			object-fit: contain;
		}
	}
}

.gallery-icon {
	color: var(--white);
	top: 3rem;
	width: 1.5rem;
	height: 1.5rem;
	cursor: pointer;
	transition: all 0.2s ease-in-out;

	&:hover {
		transform: scale(1.1);
	}
}

.gallery-icon.close-icon {
	width: 2rem;
	height: 2rem;
}

.gallery-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 30;
	padding: 1rem;
	background-color: var(--black);

	@include screen-up(lg) {
		padding: 0;
	}
}

.grid-buttons-container {
	position: relative;
	display: flex;
	gap: 1rem;
	align-items: center;
}

.share-content {
	top: 0;
	right: 0;
	transform: translateY(60%);
	background-color: var(--white);
	position: absolute;
	padding: 0.7rem 1rem;
	border-radius: 0.4rem;
	width: 125px;
}

.grid-wrapper {
	display: flex;
	flex-direction: column;
	position: relative;
	overflow-y: scroll;
	height: 100%;
	transition: all 0.3s ease-in-out;

	@include scrollbar-styling();
}

.gallery-navigation {
	background-color: var(--black);
	display: flex;
	justify-content: space-between;
	width: 100%;
	height: v-bind(navigationHeight);
	transition: all 0.3s ease-in-out;
	padding: 0 2rem 0 0;
	position: fixed;
	top: 0;

	@include screen-up(lg) {
		padding: 2rem 6rem;
	}

	.icon {
		@include gallery-navigation-item;
	}

	.tab-navigation-item {
		@include gallery-navigation-item;

		color: var(--white);
		padding: 0.5rem 1.5rem;
		font-family: var(--default-font);
		font-size: 0.9rem;
		font-weight: 500;
		transition: all 0.2s ease-in-out;
		background-color: var(--black);
		color: var(--white);

		&:hover {
			background-color: var(--darkgray);
		}

		&.active {
			background-color: var(--darkgray);
			color: var(--white);

			&:hover {
				filter: brightness(1.2);
			}
		}
	}

	& > .gallery-tabs-navigation-container {
		display: flex;
		gap: 1rem;
		align-items: center;
	}
}

.video-iframe {
	width: 100%;
	aspect-ratio: 16 / 9;

	@supports not (aspect-ratio: 16 / 9) {
		height: 100%;
	}
}
</style>
