<template>
	<input
		ref="timePickerInput"
		type="time"
		placeholder="HH:MM"
		:value="modelValue"
		@click="showTimePicker"
		@input="handleInput"
	/>
</template>

<script setup lang="ts">
defineOptions({ name: 'TimeInput' });

type Emits = {
	(e: 'update:modelValue', value: string): void;
};

type TimeInputProps = {
	modelValue: string | null;
};

const emit = defineEmits<Emits>();

defineProps<TimeInputProps>();

function handleInput(event: Event): void {
	const element = event.target as HTMLInputElement | HTMLTextAreaElement;

	emit('update:modelValue', element.value);
}

const timePickerInput = ref<HTMLInputElement | null>(null);

function showTimePicker(): void {
	if (!timePickerInput.value) return;

	timePickerInput.value.showPicker();
}
</script>
<style scoped lang="scss">
input {
	&::-webkit-inner-spin-button,
	&::-webkit-calendar-picker-indicator {
		display: none;
		appearance: none;
		-webkit-appearance: none;
	}
}
</style>
