<template>
	<div
		v-on-click-outside="() => toggleDropdown(false)"
		class="custom-select-wrapper"
	>
		<button
			class="select-option default-font selected-option custom-select"
			type="button"
			aria-haspopup="listbox"
			:aria-expanded="isExpanded"
			@click="toggleDropdown(true)"
		>
			<slot v-if="modelValue" name="option" :option="selectedOption"> </slot>
			<span v-else>
				{{ placeholder }}
			</span>
			<span class="select-icon">
				<DropdownIcon />
			</span>
		</button>

		<ul class="select-options" role="listbox">
			<li
				v-for="option in options"
				:key="`${option.label}:${option.value}:${option.icon || 'icon-unset'}`"
				role="option"
				class="select-option default-font"
				:class="{ selected: selectedOption?.value === option.value }"
				@click="changeValue(option.value)"
			>
				<slot name="option" :option="option"></slot>
			</li>
		</ul>
	</div>
</template>

<script setup lang="ts">
import { vOnClickOutside } from '@vueuse/components';

import type { IconComponent } from '@SHARED/presenters/PropertyPresenter';

import DropdownIcon from '~icons/mdi/chevron-down';

defineOptions({ name: 'SelectField' });

export type Option = {
	label: string;
	value: string;
	icon?: string | IconComponent;
};

type Emits = {
	(e: 'update:modelValue', value: string): void;
};

const emit = defineEmits<Emits>();

type SelectFieldProps = {
	modelValue: string;
	placeholder: string;
	options: Option[];
};

const props = defineProps<SelectFieldProps>();

const isExpanded = ref<boolean>(false);

const selectedOption = computed<Option | null>(
	() => props.options.find(option => option.value === props.modelValue) || null
);

function changeValue(value: string) {
	emit('update:modelValue', value);

	toggleDropdown(false);
}

function toggleDropdown(state: boolean) {
	isExpanded.value = state;
}
</script>

<style lang="scss" scoped>
@import '@SHARED/assets/style/mixins/scrollbar.scss';

.custom-select-wrapper {
	display: flex;
	position: relative;
	width: 100%;

	.custom-select[aria-expanded='true'] {
		+ .select-options {
			display: flex;
			flex-direction: column;
		}
	}

	&:focus,
	&:focus-within {
		.select-options {
			display: flex;
			flex-direction: column;
		}
	}

	.select-options {
		margin-top: 0.5rem;
		display: none;
		position: absolute;
		top: 100%;
		left: 0;
		background-color: var(--white);
		width: 100%;
		overflow-y: auto;
		max-height: 200px;
		box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
		z-index: 1;

		@include scrollbar-styling();

		.select-option {
			padding: 0.75rem;
			cursor: pointer;
		}
	}
}

.select-option {
	display: flex;
	align-items: center;
	position: relative;
	width: 100%;
	transition: background-color 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

	&:not(.selected-option) {
		&:hover {
			background-color: var(--offwhite);
		}
	}

	.select-icon {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
	}

	&.selected {
		background-color: var(--lightgray);
	}
}
</style>
