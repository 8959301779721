type MinisiteVariantData = {
	isExclusivesMinisite: boolean;
	isOpportunitiesMinisite: boolean;
	isRegularMinisite: boolean;
};

function removeUrlProtocol(url: string): string {
	return url.replace('https://', '').replace('http://', '');
}

export function useMinisiteVariant(): MinisiteVariantData {
	const domain = useState<string>('domain');

	const runtimeConfig = useRuntimeConfig().public;

	const exclusivesMinisiteUrl = removeUrlProtocol(
		runtimeConfig.exclusivesMinisiteUrl
	);

	const opportunitiesMinisiteUrl = removeUrlProtocol(
		runtimeConfig.opportunitiesMinisiteUrl
	);

	const isExclusivesMinisite = domain.value.includes(exclusivesMinisiteUrl);

	const isOpportunitiesMinisite = domain.value.includes(
		opportunitiesMinisiteUrl
	);

	const isRegularMinisite = !isExclusivesMinisite && !isOpportunitiesMinisite;

	return {
		isExclusivesMinisite,
		isOpportunitiesMinisite,
		isRegularMinisite
	};
}
