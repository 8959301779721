import type { CountryPhoneData } from '@SHARED/core/ports/services/PhoneService';
import type { Option } from '@SHARED/components/atoms/SelectField.vue';

import { countriesPhoneDataList } from '@SHARED/utils/phone/countries';
import { Sorting } from '@SHARED/core/helpers/sorting';

type GetPhoneOptionsConfig = {
	placeBrazilAndUSAFirst?: boolean;
};

export class PhonePresenter {
	static getPhoneOptions({
		placeBrazilAndUSAFirst = false
	}: GetPhoneOptionsConfig = {}): Option[] {
		const sortedOptions = placeBrazilAndUSAFirst
			? getCountriesPhoneDataListWithBrazilAndUSAFirst(countriesPhoneDataList)
			: countriesPhoneDataList;

		return sortedOptions.map(country => ({
			label: country.code,
			value: country.code,
			icon: country.flagUrl
		}));
	}
}

function getCountriesPhoneDataListWithBrazilAndUSAFirst(
	countriesPhoneDataList: CountryPhoneData[]
): CountryPhoneData[] {
	return countriesPhoneDataList.slice().sort((a, b) => {
		if (a.iso === 'BR') return Sorting.PLACE_A_FIRST;

		if (a.iso === 'US') {
			return b.iso === 'BR' ? Sorting.PLACE_B_FIRST : Sorting.PLACE_A_FIRST;
		}

		return Sorting.KEEP_ORDER;
	});
}
