<template>
	<div
		class="expiration-modal-overlay absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center bg-[#efefef]"
	>
		<div
			class="flex h-full w-full flex-col items-center justify-center bg-white md:h-[380px] md:w-[558px] md:rounded-[20px] md:pb-[60px] md:pt-[86px] md:drop-shadow-[0px_8px_8px_rgba(0,0,0,0.14)]"
		>
			<strong
				class="w-1/2 text-center text-xl font-medium leading-6 text-[#000] md:w-auto md:text-2xl md:leading-7"
			>
				Este link não está mais disponível
			</strong>

			<div class="mt-9 w-9/12 md:w-3/5">
				<hr class="expiration-modal-divider" />

				<span
					class="mt-[26px] block text-center text-sm font-normal leading-[120%] text-[#000] md:mt-[21px] md:text-base"
				>
					Para ter acesso novamente às informações, solicite uma atualização
				</span>

				<hr class="mt-[25px] h-0.5 bg-[#1d1d1b] md:mt-[34px]" />

				<ButtonBlock
					v-if="phone"
					class="mt-7 w-full"
					text="Entrar em contato"
					background-color="black"
					text-color="white"
					gap="0.5rem"
					target="_blank"
					role="button"
					:url="`https://wa.me/${phone}`"
					@click="emit('click-cta', phone)"
				>
					<template #before>
						<Icon
							name="PilarWhatsapp"
							class="md:w:6 mr-2.5 flex h-5 w-5 items-center justify-center md:mr-5 md:h-6"
						/>
					</template>
				</ButtonBlock>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import ButtonBlock from '@SHARED/components/blocks/ButtonBlock.vue';

defineProps<{
	phone: string | null;
}>();

type Emits = {
	(event: 'click-cta', phone: string | null): void;
};

const emit = defineEmits<Emits>();
</script>
