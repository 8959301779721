<template>
	<div>
		<SelectField
			:model-value="modelValue.countryCode"
			:options="countryOptions"
			placeholder="Código do país"
			:class="selectClass"
			:data-rounded="borderRadius"
			@update:model-value="updatePhoneField('countryCode', $event)"
		>
			<template #option="{ option }">
				<!-- TODO: olhar com mais calma a tipagem de option.icon e alterar se necessário -->
				<NuxtImg
					v-if="showCountryFlag"
					class="country-icon"
					:alt="option.label"
					:src="String(option.icon)"
				/>
				{{ option.label }}
			</template>
		</SelectField>
		<InputField
			:id="id"
			:model-value="modelValue.number"
			:mask="country.mask"
			:class="inputClass"
			:placeholder="placeholder"
			@update:model-value="updatePhoneField('number', $event)"
		/>
	</div>
</template>

<script setup lang="ts">
import type {
	BorderRadius,
	WebsiteStyles
} from '@SHARED/core/entities/WebsiteConfig';
import type { CountryPhoneData } from '@SHARED/core/ports/services/PhoneService';

import { PHONE_SERVICE } from '@SHARED/utils/vueProvidersSymbols';

import { PhonePresenter } from '@SHARED/presenters/PhonePresenter';
import { useId } from '@SHARED/composables/useId';

import SelectField from '@SHARED/components/atoms/SelectField.vue';
import InputField from '@SHARED/components/atoms/InputField.vue';

defineOptions({ name: 'PhoneInput' });

const phoneService = inject(PHONE_SERVICE)!;

const styles = useState<WebsiteStyles>('styles');

const attrs = useAttrs();

const validBorderRadius = ['pill', 'rounded', 'square'] as const;

const borderRadius = computed<BorderRadius>(() => {
	const dataRounded = attrs['data-rounded'] as BorderRadius;

	if (!dataRounded) return styles.value.appearance.defaultBorderRadius;

	if (validBorderRadius.includes(dataRounded)) return dataRounded;

	return styles.value.appearance.defaultBorderRadius;
});

export type Phone = {
	number: string;
	countryCode: string;
};

type PhoneInput = {
	id?: string;
	selectClass?: string;
	inputClass?: string;
	modelValue: Phone;
	placeholder?: string;
	showCountryFlag?: boolean;
};

const props = withDefaults(defineProps<PhoneInput>(), {
	selectClass: '',
	inputClass: '',
	id: '',
	placeholder: '',
	showCountryFlag: true
});

type Emits = {
	(e: 'update:modelValue', value: Phone): void;
	(e: 'update:mask', value: string | string[]): void;
};

const emit = defineEmits<Emits>();

const countryOptions = PhonePresenter.getPhoneOptions({
	placeBrazilAndUSAFirst: true
});

const id =
	props.id ||
	useId(
		'phone-input',
		`${props.placeholder}-${props.selectClass}-${props.inputClass}`
	);

const country = computed<CountryPhoneData>(() =>
	phoneService.getPhoneDataByField('code', props.modelValue.countryCode)
);

function updatePhoneField(field: keyof Phone, value: string) {
	emit('update:modelValue', { ...props.modelValue, [field]: value });
	emit('update:mask', country.value.mask);
}
</script>

<style lang="scss" scoped>
.phone-input-container {
	position: relative;
	display: flex;

	select {
		position: absolute;
		z-index: 3;
		height: 100%;
		top: 0;
		left: 0;
	}
}

.phone-select {
	color: var(--white);
	border-style: solid;
	border-color: var(--white);
	font-size: 0.9rem;

	opacity: 0.6;

	&:focus {
		opacity: 1;
		outline: none;
	}

	option {
		display: flex;
		align-items: center;
		width: 100%;
		color: var(--black);
	}
}

.campaign-input {
	width: 100%;
	border: 1px solid var(--lightgray);
	padding: 0.75rem;

	&-gray {
		width: 100%;
		border: 1px solid #ccc;
	}
}

.campaign-phone-select {
	background-color: var(--offwhite);
	border: 1px solid #ccc;
	border-right: none;
	padding: 0.75rem;
	max-width: 20%;
	min-width: 6.875rem;
}

.country-icon {
	width: 1.25rem;
	height: 1.25rem;
	margin-right: 0.5rem;
}

.send-property-phone-select {
	padding: 0.625rem 0.875rem;
	width: 40%;
	border: solid 1px var(--lightgray);
	border-right-width: 0;

	@include screen-up(sm) {
		width: 35%;
	}
}

.send-property-input {
	padding: 0.625rem 0.875rem;
	border: solid 1px var(--lightgray);
	background-color: var(--white);
	width: 65%;
}
</style>
