<template>
	<Component
		:is="tag"
		class="p-button"
		:data-variant="variant"
		:style="{
			'--button-color': buttonColorCssCustomProperty,
			'--button-text-color': buttonTextColorCssCustomProperty
		}"
	>
		<slot></slot>
	</Component>
</template>

<script setup lang="ts">
import type { Color } from '@SHARED/core/entities/WebsiteConfig';
import type { CssVar } from '@/utils/helperTypes';

defineOptions({ name: 'PButton' });

type PButtonProps = {
	variant?: 'default' | 'outline';
	color?: Color;
	textColor?: Color;
	tag?: keyof HTMLElementTagNameMap;
};

const props = withDefaults(defineProps<PButtonProps>(), {
	variant: 'default',
	color: 'black',
	textColor: 'white',
	tag: 'button'
});

const buttonColorCssCustomProperty = computed<CssVar<Color>>(
	() => `var(--${props.color})`
);

const buttonTextColorCssCustomProperty = computed<CssVar<Color>>(
	() => `var(--${props.textColor})`
);
</script>

<style lang="postcss" scoped>
.p-button {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.75rem 1.25rem;
	font-size: 1rem;
	line-height: 140%;
	border-radius: 0.25rem;

	background-color: var(--button-color);
	color: var(--button-text-color);

	&[data-variant='outline'] {
		background-color: transparent;
		color: var(--button-color);
		border: 1px solid var(--button-color);
	}
}
</style>
