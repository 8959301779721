<template>
	<article class="information-wrapper">
		<NuxtLink
			v-if="!hideWhatsapp && whatsappMessageUrl"
			v-cta="{
				action: 'whatsapp',
				trigger: 'broker-info'
			}"
			:to="whatsappMessageUrl"
			external
			target="_blank"
			rel="noopener"
			class="contact-button"
		>
			<Icon name="mdi:whatsapp" class="contact-button-icon" />
		</NuxtLink>
		<NuxtLink
			v-if="email"
			:to="`mailto:${email}`"
			external
			target="_blank"
			rel="noopener"
			class="contact-button"
		>
			<Icon name="mdi:email-outline" class="contact-button-icon" />
		</NuxtLink>

		<div v-if="brokerName" class="text-wrapper">
			<span class="broker-name">
				{{ brokerName }}
			</span>
			<span class="broker-role"> Corretor imobiliário </span>
		</div>
	</article>
</template>

<script setup lang="ts">
defineOptions({ name: 'BrokerInformation' });

type TextColor = 'black' | 'white';

type BrokerInformationProps = {
	whatsappMessageUrl?: string | null;
	email?: string | null;
	brokerName?: string | null;
	hideWhatsapp?: boolean;
	textColor?: TextColor;
};

const props = withDefaults(defineProps<BrokerInformationProps>(), {
	brokerName: null,
	email: null,
	whatsappMessageUrl: null,
	hideWhatsapp: false,
	textColor: 'black'
});

const textColor = computed(() =>
	props.textColor === 'black' ? '#000' : '#fff'
);

const oppositeTextColor = computed(() =>
	props.textColor === 'black' ? '#fff' : '#000'
);

const brokerRoleTextColor = computed(() =>
	props.textColor === 'black' ? '#4c4c4c' : '#ccc'
);
</script>

<style lang="scss" scoped>
.information-wrapper {
	display: flex;
	align-items: center;
	gap: 1rem;
}

.contact-button {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 9999px;
	border: 2px solid v-bind(textColor);
	background-color: transparent;
	padding: 0.5rem;
	color: v-bind(textColor);
	transition: all 350ms ease-in-out;

	&:hover {
		background-color: v-bind(textColor);
		color: v-bind(oppositeTextColor);
	}

	.contact-button-icon {
		width: 1.5rem;
		height: 1.5rem;
	}
}

.text-wrapper {
	display: flex;
	flex-direction: column;
	gap: 0.125rem;

	font-family: var(--default-font);
	color: v-bind(textColor);

	.broker-name {
		font-size: 0.875rem;
		line-height: 1.42;
		font-weight: 500;
	}

	.broker-role {
		font-size: 0.75rem;
		line-height: 1.5;
		color: v-bind(brokerRoleTextColor);
	}
}
</style>
