<template>
	<section class="image-carousel-wrapper" :data-variant="variant">
		<Swiper
			:slides-per-view="slidesPerView"
			:breakpoints="breakpoints"
			:space-between="0"
			:modules="modules"
			:zoom="props.modules.includes('zoom')"
			class="custom-swiper"
		>
			<CarouselButtons
				:data-variant="variant"
				:variant="variant"
				:current-image-index="currentImageIndex"
				:has-videos-gallery="hasVideosGallery"
				@open-gallery="emit('open-gallery')"
				@open-video-gallery="emit('open-video-gallery')"
			/>
			<SwiperSlide
				v-for="(image, index) in images"
				:key="`property-image:${image.url}:${index}`"
				:zoom="props.modules.includes('zoom')"
				class="carousel-slide-container"
				@click="$emit('toggle-image-carousel-modal', true, index)"
			>
				<NuxtImg
					:alt="image.caption"
					width="100%"
					:placeholder="DEFAULT_NUXT_IMAGE_PLACEHOLDER"
					loading="lazy"
					:src="image.url"
				/>
			</SwiperSlide>
		</Swiper>

		<button
			v-if="variant === 'modal'"
			class="close-icon"
			@click="$emit('toggle-image-carousel-modal', false, 0)"
		>
			<CloseIcon />
		</button>
	</section>
</template>

<script setup lang="ts">
import 'swiper/css/zoom';
import 'swiper/css/navigation';

import type { SwiperOptions } from 'swiper/types';
import type { CssSize } from '@SHARED/utils/helperTypes';

import { onKeyStroke } from '@vueuse/core';
import { Zoom, Navigation } from 'swiper/modules';
import { DEFAULT_NUXT_IMAGE_PLACEHOLDER } from '@SHARED/utils/style';

import CarouselButtons from '@SHARED/components/molecules/CarouselButtons.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';

import CloseIcon from '~icons/mdi/close';

defineOptions({ name: 'ImageCarousel' });

type CarouselVariant = 'default' | 'modal';
type ModulesKey = 'zoom' | 'navigation';

export type CarouselImage = {
	url: string;
	caption: string;
};

type ImageCarousel = {
	images: CarouselImage[];
	hasVideosGallery?: boolean;
	variant?: CarouselVariant;
	currentImageIndex?: number;
	coordinates?: {
		lat: number;
		lng: number;
	} | null;
	slidesPerView?: number;
	carouselHeight?: {
		mobile: CssSize | null;
		desktop: CssSize | null;
	};
	modules?: ('zoom' | 'navigation')[];
};

const props = withDefaults(defineProps<ImageCarousel>(), {
	variant: 'modal',
	currentImageIndex: 0,
	hasVideosGallery: false,
	coordinates: null,
	slidesPerView: 2,
	carouselHeight: () => ({
		mobile: null,
		desktop: null
	}),
	modules: () => []
});

type Emits = {
	(e: 'open-gallery'): void;
	(e: 'open-video-gallery'): void;
	(e: 'toggle-image-carousel-modal', state: boolean, index?: number): void;
};

const emit = defineEmits<Emits>();

const modulesDict: Record<ModulesKey, typeof Zoom | typeof Navigation> = {
	zoom: Zoom,
	navigation: Navigation
};

const carouselSlidesPerView = computed<number>(() => {
	if (props.variant === 'modal' || props.images.length < 1) return 1;

	return Math.min(props.images.length, props.slidesPerView);
});

const breakpoints = computed<SwiperOptions['breakpoints']>(() => {
	if (props.variant === 'default') {
		return {
			0: {
				slidesPerView: 1
			},
			1024: {
				slidesPerView: carouselSlidesPerView.value
			}
		};
	}

	return undefined;
});

const modules = computed(() => props.modules.map(key => modulesDict?.[key]));

onKeyStroke('Escape', () => {
	if (props.variant === 'default') return;
	emit('toggle-image-carousel-modal', false);
});

const mobileCarouselHeight = computed(
	() => props.carouselHeight.mobile || 'calc(100vh - var(--header-height))'
);

const desktopCarouselHeight = computed(
	() => props.carouselHeight.desktop || 'calc(100vh - var(--header-height))'
);
</script>

<style lang="scss" scoped>
.custom-swiper {
	height: v-bind(mobileCarouselHeight);

	@include screen-up(lg) {
		height: v-bind(desktopCarouselHeight);
	}

	.swiper-wrapper {
		height: 100%;
	}
}

.image-carousel-wrapper {
	overflow: hidden;
	background: var(--white);

	&[data-variant='default'] {
		position: relative;
		height: v-bind(mobileCarouselHeight);

		@include screen-up(lg) {
			height: v-bind(desktopCarouselHeight);
		}

		.carousel-slide-container {
			height: calc(100vh - var(--header-height));
			width: 100%;

			img {
				transform: scale(1.01);
				transition: all 400ms cubic-bezier(0.4, 0, 0.2, 1);
				position: absolute;
				z-index: 1;
				width: 100%;
				object-position: center;
				object-fit: cover;

				height: v-bind(mobileCarouselHeight);

				@include screen-up(lg) {
					height: v-bind(desktopCarouselHeight);
				}
			}

			&:hover {
				img {
					transform: scale(1.1);
					filter: brightness(80%);
				}
			}
		}
	}

	&[data-variant='modal'] {
		position: fixed;
		top: 0;
		height: 100vh;
		z-index: 40;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: rgba(0, 0, 0, 0.9);
		padding-top: 3rem;
		padding-bottom: 3rem;
		display: flex;
		align-items: center;
		justify-content: center;
		object-fit: contain;

		.carousel-slide-container {
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				width: 100%;
				height: auto;
				object-position: center;
				object-fit: contain;
				max-width: 1080px;
				max-height: 720px;
			}
		}

		& > div {
			width: 100%;
		}

		@include screen-up(lg) {
			padding: 0 8rem;
		}

		.close-icon {
			z-index: 1000000000000;
			position: fixed;
			border: none;
			padding: 0;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			color: var(--white);
			top: 2rem;
			right: 1.5rem;
			transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);

			&:hover {
				transform: scale(1.25);
			}

			@include screen-up(lg) {
				right: 3rem;
			}

			svg {
				width: 2rem;
				height: 2rem;
			}
		}
	}

	.carousel-slide-container {
		position: relative;
		overflow: hidden;
		cursor: pointer;
	}

	:deep(.swiper-zoom-container) {
		@media (max-height: 800px) {
			width: auto;
			height: auto;
		}
	}
}
</style>
