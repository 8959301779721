import type { InjectionKey } from 'vue';

import type {
	MinisiteData,
	MinisiteFeatureFlagObject
} from '@/core/entities/minisiteData';

export const MINISITE_DATA_PROVIDER: InjectionKey<MinisiteData | null> =
	Symbol('minisiteData');

export const FEATURE_FLAGS_PROVIDER: InjectionKey<MinisiteFeatureFlagObject | null> =
	Symbol('featureFlags');
