type UseBodyScrollReturn = {
	enableScrollOnBody: () => void;
	disableScrollOnBody: () => void;
};

export function useBodyScroll(): UseBodyScrollReturn {
	function enableScrollOnBody() {
		document.documentElement.removeAttribute('data-no-scroll');
	}

	function disableScrollOnBody() {
		document.documentElement.setAttribute('data-no-scroll', 'true');
	}

	return {
		enableScrollOnBody,
		disableScrollOnBody
	};
}
