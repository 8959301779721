<template>
	<section class="wrapper">
		<div class="breadcrumb-wrapper">
			<div
				v-for="(breadcrumbItem, index) in breadcrumbs"
				:key="getOptionKey(index, 1, breadcrumbItem.name)"
				class="breadcrumb-item"
			>
				<NuxtLink :to="breadcrumbItem.path" class="link">
					{{ breadcrumbItem.name }}
				</NuxtLink>
				<ChevronRight class="chevron-icon" />

				<div v-if="!index" class="more-wrapper">
					<span @click="toggleDropdown">
						<MoreHoriz class="more-horiz-icon" />
					</span>
					<ChevronRight class="chevron-icon" />
				</div>
			</div>
		</div>
		<div ref="dropdown" class="dropdown" :class="{ open: isDropdownOpen }">
			<NuxtLink
				v-for="(breadcrumbItem, index) in breadcrumbs"
				:key="getOptionKey(index, 2, breadcrumbItem.name)"
				:to="breadcrumbItem.path"
				class="link"
			>
				{{ breadcrumbItem.name }}
			</NuxtLink>
		</div>
	</section>
</template>

<script setup lang="ts">
import { onClickOutside, useWindowSize } from '@vueuse/core';
import { useGetPropertyBreadcrumb } from '@SHARED/composables/useGetPropertyBreadcrumb';

import type { Property } from '@SHARED/core/entities/Property';
import type { PropertyFilters } from '@SHARED/core/entities/Property/filters';

import ChevronRight from '~icons/mdi/chevron-right';
// TODO: Verificar import de more-horizontal custom icon
import MoreHoriz from '~icons/material-symbols/more-horiz';

type BreadcrumbProps = {
	property?: Property | null;
	filters?: PropertyFilters | null;
};

defineOptions({ name: 'PropertyBreadcrumb' });

const props = withDefaults(defineProps<BreadcrumbProps>(), {
	property: null,
	filters: null
});

const property = computed(() => props.property);
const filters = computed(() => props.filters);

const { breadcrumb } = useGetPropertyBreadcrumb({
	property,
	filters
});

interface BreadCrumb {
	name: string | null | undefined;
	path: string;
}

const breadcrumbs = computed<BreadCrumb[] | null>(() => {
	if (!breadcrumb.value) return [];

	const { transactionType, propertyType, country, state, city, region } =
		breadcrumb.value;

	return [
		{
			name: 'Início',
			path: '/imoveis'
		},
		{
			name: transactionType?.label,
			path: `/imoveis/${transactionType?.path}`
		},
		{
			name: propertyType?.label,
			path: `/imoveis/${transactionType?.path}/${propertyType?.path}`
		},
		{
			name: city?.label,
			path: `/imoveis/${transactionType?.path}/${propertyType?.path}/${country?.path}/${state?.path}/${city?.path}`
		},
		{
			name: region?.label,
			path: `/imoveis/${transactionType?.path}/${propertyType?.path}/${country?.path}/${state?.path}/${city?.path}/${region?.path}`
		}
	].filter(item => Boolean(item.name));
});

const isDropdownOpen = shallowRef<boolean>(false);

function toggleDropdown() {
	isDropdownOpen.value = !isDropdownOpen.value;
}

function closeDropdown() {
	isDropdownOpen.value = false;
}

const dropdown = ref<HTMLElement | null>(null);
const { width } = useWindowSize();

onClickOutside(dropdown, closeDropdown);

watch(width, closeDropdown);

function getOptionKey(index: number, iteration: number, name?: string | null) {
	return `breadcrumb-option:${iteration}:${name}-${index}`;
}
</script>

<style lang="scss" scoped>
.wrapper {
	white-space: nowrap;
	position: relative;

	.breadcrumb-wrapper,
	.breadcrumb-item,
	.more-wrapper {
		align-items: center;
		align-content: center;
		gap: 0.4rem;
	}

	.breadcrumb-wrapper {
		display: flex;
		position: relative;

		.breadcrumb-item {
			display: none;

			&:first-child,
			&:last-child {
				display: flex;
			}

			@include screen-up(md) {
				display: flex;
			}

			.chevron-icon,
			.more-horiz-icon {
				color: var(--gray);
				width: 1.5rem;
				height: 1.5rem;
				flex-shrink: 0;
			}

			&:last-child .chevron-icon {
				display: none;
			}

			.more-wrapper {
				display: flex;

				@include screen-up(md) {
					display: none;
				}
			}

			.more-horiz-icon {
				color: var(--black);

				&:hover {
					cursor: pointer;
				}
			}
		}
	}

	.dropdown {
		height: 0px;
		width: 335px;
		display: flex;
		overflow: hidden;
		position: absolute;
		top: 2rem;
		flex-direction: column;
		justify-content: start;
		align-items: flex-start;
		gap: 12px;
		background-color: var(--white);
		transition-property: max-height;
		transition-duration: 300ms;
		transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
		max-height: 0px;
		z-index: 5;

		@include screen-up(md) {
			display: none;
		}

		&.open {
			padding: 16px;
			max-height: 500px;
			height: auto;
			box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
		}
	}

	.link {
		color: var(--darkgray, #333);
		font-family: var(--default-font);
		font-size: 0.875rem;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		text-decoration-line: underline;

		@include screen-up(md) {
			font-size: 1rem;
		}

		&:hover {
			color: var(--black);
		}
	}
}
</style>
