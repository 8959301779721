<template>
	<div :data-variant="variant">
		<NuxtLink
			v-for="option in sharingOptions"
			:key="option.label"
			:to="option.value"
			external
			rel="nofollow external"
			target="_blank"
			class="share-icon-link"
			:aria-label="`Compartilhe no ${capitalize(option.label)}`"
			@click="$emit('click-link')"
		>
			<Component :is="option.icon" />
			<span v-if="showLabels" class="heading-font">
				{{ option.label }}
			</span>
		</NuxtLink>
		<slot></slot>
	</div>
</template>

<script setup lang="ts">
import { capitalize } from '@SHARED/utils';
import type { FunctionalComponent, SVGAttributes } from '#app/compat/capi';

import EmailIcon from '~icons/mdi/email';
import TwitterIcon from '~icons/ri/twitter-x-fill';
import WhatsAppIcon from '~icons/ri/whatsapp-fill';
import FacebookIcon from '~icons/uil/facebook-f';

defineOptions({ name: 'SharingContent' });

type Emit = { (e: 'click-link'): void };
defineEmits<Emit>();

// <!-- TODO: olhar com mais atenção para os lugares onde este componente é usado para tratar melhor as props e entender os casos de uso
type SharingOption = {
	value: string;
	label: string;
	icon: FunctionalComponent<SVGAttributes, {}, any>;
};

type SharingContentProps = {
	mailSubject: string;
	contentToShare: string;
	showLabels?: boolean;
	variant?: 'default' | 'dropdown';
};

const props = withDefaults(defineProps<SharingContentProps>(), {
	showLabels: false,
	variant: 'default'
});

const sharingOptions = computed<SharingOption[]>(() => [
	{
		value: `https://api.whatsapp.com/send/?text=${props.contentToShare}`,
		label: 'WhatsApp',
		icon: WhatsAppIcon
	},
	{
		value: `https://www.facebook.com/sharer/sharer.php?u=${props.contentToShare}`,
		label: 'Facebook',
		icon: FacebookIcon
	},
	{
		value: `https://twitter.com/share?url=${props.contentToShare}`,
		label: 'Twitter',
		icon: TwitterIcon
	},
	{
		value: `mailto:?subject=${props.mailSubject}&body=${props.contentToShare}`,
		label: 'Via e-mail',
		icon: EmailIcon
	}
]);
</script>

<style lang="scss" scoped>
[data-variant='default'] {
	display: flex;
	align-items: center;
	gap: 1rem;

	:deep(.share-icon-link) {
		transition: all 0.15s ease-in-out;
		padding: 0.5rem;
		border-radius: 50%;
		border: solid 1px var(--black);
		width: 2rem;
		height: 2rem;
		display: flex;
		align-items: center;
		justify-content: center;

		svg {
			width: 1rem;
			height: 1rem;
		}

		&:hover {
			background: var(--black);
			color: var(--white);
		}
	}
}

[data-variant='dropdown'] {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;

	:deep(.share-icon-link) {
		transition: all 0.15s ease-in-out;
		color: var(--darkgray);
		display: flex;
		align-items: center;
		gap: 1rem;
		font-size: 0.75rem;

		svg {
			width: 0.9rem;
			height: 0.9rem;
		}

		&:hover {
			color: var(--black);
		}
	}
}
</style>
