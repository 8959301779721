import type { PropertyFilters } from '@SHARED/core/entities/Property/filters';
import type { Property } from '@SHARED/core/entities/Property';
import type {
	PossiblePropertyTypeSlugValue,
	TransactionTypeSlug
} from '@SHARED/presenters/PropertiesFiltersPresenter';

import { PropertiesFiltersPresenter } from '@SHARED/presenters/PropertiesFiltersPresenter';

export type PropertyBreadcrumb = {
	transactionType: {
		label: string | null;
		path: TransactionTypeSlug | null;
	} | null;
	propertyType: {
		label: string | null;
		path: PossiblePropertyTypeSlugValue | null;
	} | null;
	country: { label: string | null; path: string | null } | null;
	state: { label: string | null; path: string | null } | null;
	city: { label: string | null; path: string | null } | null;
	region: { label: string | null; path: string | null } | null;
};

type UseGetPropertyBreadcrumbParams = {
	property?: ComputedRef<Property | null>;
	filters?: MaybeRef<PropertyFilters | null>;
};

type UseGetPropertyBreadcrumbReturn = {
	breadcrumb: ComputedRef<PropertyBreadcrumb | null>;
};

export function useGetPropertyBreadcrumb({
	property,
	filters
}: UseGetPropertyBreadcrumbParams): UseGetPropertyBreadcrumbReturn {
	function getBreadcrumbFromProperty(property: Property): PropertyBreadcrumb {
		const propertyTypes = [property.propertyType.name];
		const countries = property.country ? [property.country] : [];
		const states = [property.state];
		const cities = [property.city];
		const regions = [property.region];

		return {
			transactionType: {
				path: PropertiesFiltersPresenter.getSlugFromTransactionType(
					property.ad.transactionType
				),
				label: PropertiesFiltersPresenter.getTransactionTypeTextValue(
					property.ad.transactionType
				)
			},
			propertyType: {
				path: PropertiesFiltersPresenter.getSlugFromPropertyTypes(
					propertyTypes
				),
				label: property.propertyType.name
			},
			country: {
				path: PropertiesFiltersPresenter.getSlugFromCountries(countries),
				label: property.country
			},
			state: {
				path: PropertiesFiltersPresenter.getSlugFromStates(states),
				label: property.state
			},
			city: {
				path: PropertiesFiltersPresenter.getSlugFromCities(cities),
				label: property.city
			},
			region: {
				path: PropertiesFiltersPresenter.getSlugFromRegions(regions),
				label: property.region
			}
		};
	}

	function getTransactionTypeBreadcrumb({
		transactionType
	}: PropertyFilters): PropertyBreadcrumb['transactionType'] {
		if (!transactionType) return null;

		return {
			path: PropertiesFiltersPresenter.getSlugFromTransactionType(
				transactionType
			),
			label:
				PropertiesFiltersPresenter.getTransactionTypeTextValue(transactionType)
		};
	}

	function getPropertyTypeBreadcrumb({
		propertyTypes
	}: PropertyFilters): PropertyBreadcrumb['propertyType'] {
		if (!propertyTypes?.length) return null;

		return {
			path: PropertiesFiltersPresenter.getSlugFromPropertyTypes(propertyTypes),
			label: propertyTypes.join(', ')
		};
	}

	function getCountryBreadcrumb({
		countries
	}: PropertyFilters): PropertyBreadcrumb['country'] {
		if (!countries?.length) return null;

		return {
			path: PropertiesFiltersPresenter.getSlugFromCountries(countries),
			label: countries.join(', ') || 'Brasil'
		};
	}

	function getStateBreadcrumb({
		states
	}: PropertyFilters): PropertyBreadcrumb['state'] {
		if (!states?.length) return null;

		return {
			path: PropertiesFiltersPresenter.getSlugFromStates(states),
			label: states.join(', ') || 'Todos os estados'
		};
	}

	function getCityBreadcrumb({
		cities
	}: PropertyFilters): PropertyBreadcrumb['city'] {
		if (!cities?.length) return null;

		return {
			path: PropertiesFiltersPresenter.getSlugFromCities(cities),
			label: cities.join(', ') || 'Todas as cidades'
		};
	}

	function getRegionBreadcrumb({
		regions
	}: PropertyFilters): PropertyBreadcrumb['region'] {
		if (!regions?.length) return null;

		return {
			path: PropertiesFiltersPresenter.getSlugFromRegions(regions),
			label: regions.join(', ') || 'Todos os bairros'
		};
	}

	function getBreadcrumbFromFilters(
		filters: PropertyFilters
	): PropertyBreadcrumb {
		return {
			transactionType: getTransactionTypeBreadcrumb(filters),
			propertyType: getPropertyTypeBreadcrumb(filters),
			country: getCountryBreadcrumb(filters),
			state: getStateBreadcrumb(filters),
			city: getCityBreadcrumb(filters),
			region: getRegionBreadcrumb(filters)
		};
	}

	const breadcrumb = computed<PropertyBreadcrumb | null>(() => {
		if (property?.value) return getBreadcrumbFromProperty(property.value);

		const unrefFilters = unref(filters);

		if (unrefFilters) return getBreadcrumbFromFilters(unrefFilters);

		return null;
	});

	return {
		breadcrumb
	};
}
