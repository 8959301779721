<template>
	<div class="buttons-wrapper">
		<div class="buttons-container">
			<button
				aria-label="Ir para a imagem anterior"
				:disabled="isBeginning"
				@click="swiper.slidePrev()"
			>
				<ChevronLeft />
			</button>
			<button
				aria-label="Ir para a próxima imagem"
				:disabled="isEnd"
				@click="swiper.slideNext()"
			>
				<ChevronRight />
			</button>
		</div>

		<div class="gallery-buttons-container">
			<template v-if="variant === 'default'">
				<NuxtLink
					v-if="coordinates"
					:to="`https://www.google.com/maps/@${coordinates.lat},${coordinates.lng},15z`"
					external
					rel="nofollow external"
					target="_blank"
					class="view-option"
					aria-label="Ver no mapa"
				>
					Ver no mapa
					<div class="button-icon-container" data-rounded="pill">
						<MarkerIcon />
					</div>
				</NuxtLink>
				<button class="view-option" @click="$emit('open-gallery')">
					<span class="default-font">Galeria de Fotos</span>
					<div class="button-icon-container view-option" data-rounded="pill">
						<ImageGalleryIcon />
					</div>
				</button>
				<button
					v-if="hasVideosGallery"
					class="view-option"
					@click="$emit('open-video-gallery')"
				>
					<span class="default-font">Galeria de Vídeos</span>
					<div class="button-icon-container" data-rounded="pill">
						<VideoIcon />
					</div>
				</button>
			</template>
		</div>
	</div>
</template>

<script setup lang="ts">
import { useSwiper } from 'swiper/vue';
import { onKeyStroke } from '@vueuse/core';

import ChevronLeft from '~icons/mdi/chevron-left';
import ChevronRight from '~icons/mdi/chevron-right';
import MarkerIcon from '~icons/mdi/map-marker-outline';
import ImageGalleryIcon from '~icons/mdi/image-outline';
import VideoIcon from '~icons/mdi/play-box-outline';

type CarouselVariant = 'default' | 'modal';

type CarouselButtonsProps = {
	currentImageIndex?: number;
	coordinates?: {
		lat: number;
		lng: number;
	} | null;
	hasVideosGallery?: boolean;
	variant?: CarouselVariant;
};

type CarouselButtonsEmits = {
	(e: 'open-gallery'): void;
	(e: 'open-video-gallery'): void;
};

const props = withDefaults(defineProps<CarouselButtonsProps>(), {
	currentImageIndex: 0,
	coordinates: null,
	hasVideosGallery: false,
	variant: 'modal'
});

defineEmits<CarouselButtonsEmits>();

const swiper = useSwiper();

watch(
	() => props.currentImageIndex,
	() => {
		swiper.value.slideTo(props.currentImageIndex);
	}
);

const isBeginning = shallowRef(false);
const isEnd = shallowRef(false);

swiper.value.on('reachEnd', () => {
	isEnd.value = true;
});

swiper.value.on('reachBeginning', () => {
	isBeginning.value = true;
});

swiper.value.on('fromEdge', swiper => {
	isEnd.value = swiper.isEnd;
	isBeginning.value = swiper.isBeginning;
});

swiper.value.on('beforeInit', () => {
	swiper.value.slideTo(0);
});

onKeyStroke('ArrowRight', () => swiper.value.slideNext());
onKeyStroke('ArrowLeft', () => swiper.value.slidePrev());
</script>

<style lang="scss">
.buttons-wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: min-content;
	z-index: 1;

	.gallery-buttons-container {
		display: flex;
		align-items: flex-end;
		flex-direction: column-reverse;
		gap: 0.5rem;

		@include screen-up(lg) {
			gap: 2.25rem;
			align-items: center;
			flex-direction: row;
		}

		.view-option {
			text-transform: uppercase;
			color: var(--white);
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 1rem;
			transition: all 0.2s ease-in-out;

			&:hover {
				transform: scale(1.05);
				cursor: pointer;
			}

			.button-icon-container {
				background-color: var(--white);
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0.125rem;
				width: 1.75rem;
				height: 1.75rem;

				@include border-radius-size();

				svg {
					width: 1.25rem;
					height: 1.25rem;
					color: var(--black);
				}
			}
		}
	}

	.buttons-container {
		display: contents;
		align-items: center;
		justify-content: space-between;

		button {
			border: 2px solid var(--white);
			border-radius: 5rem;
			padding: 0.25rem;
			color: var(--white);
			transition: all 0.2s ease-in-out;
			background-color: transparent;

			&:disabled {
				opacity: 0.5;
				cursor: not-allowed;
			}

			&:hover {
				color: var(--black);
				background-color: var(--white);
			}

			@include screen-up(sm) {
				padding: 0.5rem;
			}
		}

		svg {
			width: 1.5rem;
			height: 1.5rem;
		}
	}

	&[data-variant='default'] {
		position: absolute;
		bottom: 0;
		padding: 1rem 1.5rem;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: rgba(0, 0, 0, 0.3);

		@include screen-up(md) {
			padding: 1rem 3rem;
			bottom: 0;
		}

		.buttons-container {
			display: flex;
			gap: 0.5rem;
		}
	}

	&[data-variant='modal'] {
		position: fixed;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		width: 100%;
		content: none;
		height: 0px;
		padding-left: 1rem;
		padding-right: 1rem;

		@include screen-up(lg) {
			padding-left: 3rem;
			padding-right: 3rem;
		}

		.buttons-container {
			width: 100%;
		}

		.gallery-buttons-container {
			display: none;
		}
	}

	// TODO: Quando formos utilizar de fato essa variação, precisamos revisar visualmente essa parte
	&[data-variant='koller'] {
		height: 100%;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		height: 100%;
		width: calc(100% - calc(2 * 0.75rem));
		display: flex;
		flex-direction: row;
		align-items: center;

		@include screen-up(lg) {
			background-color: var(--white);
			right: 0;
			transform: translateY(-50%);
			width: 16.5%;
			flex-direction: column;
			align-items: flex-end;
			justify-content: center;
			gap: 1.5rem;
			height: 100%;
		}

		.gallery-buttons-container {
			display: flex;
			align-items: flex-end;
			flex-direction: column-reverse;
			gap: 0.5rem;

			.view-option {
				color: var(--black);
				gap: 1rem;

				.button-icon-container {
					background-color: var(--black);

					svg {
						width: 1.25rem;
						height: 1.25rem;
						color: var(--white);
					}
				}
			}
		}

		.buttons-container {
			display: flex;
			gap: 1rem;
			align-items: center;
			justify-content: space-between;

			button {
				border: 2px solid var(--black);
				color: var(--black);
			}

			svg {
				width: 1.5rem;
				height: 1.5rem;
			}
		}
	}
}
</style>
