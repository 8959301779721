import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/zoom';

import type { SwiperOptions } from 'swiper/types';
import { Swiper } from 'swiper';
import { Navigation, Zoom } from 'swiper/modules';

export const carouselContainerClass = 'swiper';
export const carouselWrapperClass = 'swiper-wrapper';
export const carouselItemClass = 'swiper-slide';
export const zoomContainerClass = 'swiper-zoom-container';

export type UseCarouselOptions = Omit<SwiperOptions, 'modules'> & {
	shouldWatchCarouselContainerElement?: boolean;
};

const defaultModules = [Navigation];

export function useCarousel(
	{
		shouldWatchCarouselContainerElement,
		...swiperOptions
	}: UseCarouselOptions = {
		shouldWatchCarouselContainerElement: false
	}
) {
	const swiper = ref<Swiper | null>(null);
	const carouselContainer = ref<HTMLElement | null>(null);

	const currentImageCarouselIndex = ref(0);

	const unwatchCarouselContainer = watch(
		carouselContainer,
		containerElement => {
			if (!containerElement) return;

			const modules = swiperOptions.zoom
				? [...defaultModules, Zoom]
				: defaultModules;

			swiper.value = new Swiper(containerElement, {
				...swiperOptions,
				modules
			});

			swiper.value.on('slideChange', ({ activeIndex }) => {
				currentImageCarouselIndex.value = activeIndex;
			});

			if (!shouldWatchCarouselContainerElement) {
				unwatchCarouselContainer();
			}
		},
		{ immediate: true }
	);

	function goToNextImage() {
		if (!swiper.value) return;

		swiper.value.slideNext();
	}

	function goToPreviousImage() {
		if (!swiper.value) return;

		swiper.value.slidePrev();
	}

	function goTo(index: number) {
		if (!swiper.value) return;

		swiper.value.slideTo(index);
	}

	return {
		carouselContainer,
		carouselContainerClass,
		carouselWrapperClass,
		carouselItemClass,
		zoomContainerClass,
		carousel: reactive({
			currentIndex: readonly(currentImageCarouselIndex),
			goToNextImage,
			goToPreviousImage,
			goTo
		})
	};
}
