import type { Property } from '@SHARED/core/entities/Property';

const wantedKeys = [
	'bedrooms',
	'suites',
	'bathrooms',
	'parkingSpots',
	'area',
	'totalArea',
	'askingPrice',
	'rentPrice'
] as const;

type WantedKey = (typeof wantedKeys)[number];

export type WantedPropertyData = Record<WantedKey, number>;

type GetRecommendationOptions = {
	power?: 1 | 2;
	quantity?: number;
};

const zeroedWantedPropertyData: WantedPropertyData = wantedKeys.reduce(
	(acc, key) => ({
		...acc,
		[key]: 0
	}),
	{} as WantedPropertyData
);

export const PropertiesRecommendationService = {
	getPropertiesMean(properties: Property[]): WantedPropertyData {
		const valueSums = properties.reduce(
			(acc, property) => ({
				bedrooms: (acc.bedrooms || 0) + (property.bedrooms || 0),
				suites: (acc.suites || 0) + (property.suites || 0),
				bathrooms: (acc.bathrooms || 0) + (property.bathrooms || 0),
				parkingSpots: (acc.parkingSpots || 0) + (property.parkingSpots || 0),
				area: (acc.area || 0) + (property.area || 0),
				totalArea: (acc.totalArea || 0) + (property.totalArea || 0),
				askingPrice: (acc.askingPrice || 0) + (property.askingPrice || 0),
				rentPrice: (acc.rentPrice || 0) + (property.rentPrice || 0)
			}),
			{} as WantedPropertyData
		);

		return wantedKeys.reduce(
			(acc, key) => ({
				...acc,
				[key]: valueSums[key] / properties.length || 0
			}),
			{} as WantedPropertyData
		);
	},
	getPropertiesMinValues(properties: Property[]): WantedPropertyData {
		if (!properties.length) return zeroedWantedPropertyData;

		return wantedKeys.reduce((acc, key) => {
			const [propertyWithLowestValue] = properties.sort(
				(a, b) => (a[key] || 0) - (b[key] || 0)
			);

			return {
				...acc,
				[key]: propertyWithLowestValue[key] || 0
			};
		}, {} as WantedPropertyData);
	},
	getPropertiesMaxValues(properties: Property[]): WantedPropertyData {
		if (!properties.length) return zeroedWantedPropertyData;

		return wantedKeys.reduce((acc, key) => {
			const [propertyWithHighestValue] = properties.sort(
				(a, b) => (b[key] || 0) - (a[key] || 0)
			);

			return {
				...acc,
				[key]: propertyWithHighestValue[key] || 0
			};
		}, {} as WantedPropertyData);
	},
	getRecommendation(
		propertyDataReference: WantedPropertyData,
		properties: Property[],
		{ power = 2, quantity = 3 }: GetRecommendationOptions = {}
	): Property[] {
		if (!properties.length) return [];

		const minValues =
			PropertiesRecommendationService.getPropertiesMinValues(properties);
		const maxValues =
			PropertiesRecommendationService.getPropertiesMaxValues(properties);

		function getNormalizedValue(value: number, key: WantedKey): number {
			return (value - minValues[key]) / (maxValues[key] - minValues[key] || 1);
		}

		type SimilarityObject = {
			property: Property;
			similarity: number;
		};

		const similarityObjects: SimilarityObject[] = properties.map(property => {
			const sum = wantedKeys.reduce((total, key) => {
				const propertyDataReferenceValue = getNormalizedValue(
					propertyDataReference[key],
					key
				);

				const propertyValue = getNormalizedValue(property[key] || 0, key);

				const absoluteDifferenceBetweenValues = Math.abs(
					propertyDataReferenceValue - propertyValue
				);

				return total + absoluteDifferenceBetweenValues ** power;
			}, 0);

			const distance = sum ** (1 / power);

			const similarity = 1 / (1 + distance);

			return { property, similarity };
		});

		return similarityObjects
			.sort((a, b) => b.similarity - a.similarity)
			.slice(0, quantity)
			.map(obj => obj.property);
	}
};
