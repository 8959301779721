function getTodayDateNumbers(): [number, number, number] {
	const [todayDate, todayMonth, todayFullYear] = new Date()
		.toLocaleDateString('pt-BR')
		.split('/')
		.map(Number);

	return [todayDate, todayMonth, todayFullYear];
}

export function isFutureDate(value: string): boolean {
	const [todayDate, todayMonth, todayFullYear] = getTodayDateNumbers();

	const [day, month, year] = value.split('/').map(Number);

	if (!day || !month || !year) return false;

	if (year > todayFullYear) return true;
	if (year < todayFullYear) return false;

	if (month > todayMonth) return true;
	if (month < todayMonth) return false;

	return day > todayDate;
}

export function isCurrentDate(value: string): boolean {
	const [todayDate, todayMonth, todayFullYear] = getTodayDateNumbers();

	const [day, month, year] = value.split('/').map(Number);

	if (!day || !month || !year) return false;

	return day === todayDate && month === todayMonth && year === todayFullYear;
}

export function isValidDate(value: string): boolean {
	const stringValues = value.split('/').map(str => str.trim());

	if (stringValues.length !== 3) return false;

	const [dayString, monthString, yearString] = stringValues;

	if (dayString.length !== 2 || monthString.length !== 2) return false;
	if (yearString.length !== 4) return false;

	const day = +dayString;
	const month = +monthString;

	if (!day || !month) return false;

	// TODO: melhorar com validação de meses com 30 e 31 dias e tratamento específico para fevereiro
	return day >= 1 && day <= 31 && month >= 1 && month <= 12;
}

export function isValidTimeString(string: string): boolean {
	const stringSplitArray = string
		.split(':')
		.map(str => str.trim())
		.filter(Boolean);

	if (stringSplitArray.length !== 2) return false;

	const [hour, minutes] = stringSplitArray;

	if (!hour || !minutes) return false;
	if (hour.length !== 2 || minutes.length !== 2) return false;

	const hourAsNumber = +hour;
	const minutesAsNumber = +minutes;

	if (hourAsNumber < 0 || hourAsNumber > 23) return false;
	if (minutesAsNumber < 0 || minutesAsNumber > 59) return false;

	return true;
}
