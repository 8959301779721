import type { GalleryTab } from '@SHARED/components/molecules/MediaGallery.vue';

type UseMediaGalleryReturn = {
	galleryActiveTab: Ref<GalleryTab>;
	isGalleryOpen: Ref<boolean>;
	openGallery: (context: GalleryTab) => void;
	closeGallery: () => void;
};

export function useMediaGallery(): UseMediaGalleryReturn {
	const isGalleryOpen = ref<boolean>(false);
	const galleryActiveTab = ref<GalleryTab>('photos');

	function openGallery(context: GalleryTab) {
		galleryActiveTab.value = context;
		isGalleryOpen.value = true;
		document.documentElement.setAttribute('data-no-scroll', 'true');
	}

	function closeGallery() {
		isGalleryOpen.value = false;
		document.documentElement.removeAttribute('data-no-scroll');
	}
	return {
		isGalleryOpen,
		galleryActiveTab,
		openGallery,
		closeGallery
	};
}
