<template>
	<div class="property-navigation-wrapper">
		<button
			v-if="previousButtonText"
			class="property-carousel-navigation-button previous"
			aria-label="Imóvel anterior"
			:data-rounded="borderRadiusVariant"
			@click.stop="emit('go-to-previous-property')"
		>
			<ChevronLeft class="property-carousel-button-icon" />
			<span class="button-text">{{ previousButtonText }}</span>
		</button>
		<button
			v-if="nextButtonText"
			class="property-carousel-navigation-button next"
			aria-label="Próximo imóvel"
			:data-rounded="borderRadiusVariant"
			@click.stop="emit('go-to-next-property')"
		>
			<span class="button-text">{{ nextButtonText }}</span>
			<ChevronRight class="property-carousel-button-icon" />
		</button>

		<div class="property-navigation-container">
			<div>
				<BrokerInformation
					v-if="brokerName"
					:broker-name="brokerName"
					:text-color="brokerInformationTextColor"
					class="broker-information"
				/>
			</div>

			<div class="property-contact-buttons-container">
				<template v-if="acceptPartnershipRequestUrl">
					<AcceptPartnershipButton
						:source="isViewingGallery ? 'gallery' : 'expanded-property-card'"
						:accept-request-url="acceptPartnershipRequestUrl"
						:color="parnetshipButtonColor"
						:use-cta-directive="true"
						class="accept-partnership-button"
					/>
				</template>
				<template v-else>
					<ButtonBlock
						v-if="shouldShowGetInTouchButton"
						v-cta="{
							commercialId: activePropertyCommercialId,
							propertySource: isFromRecommendation ? 'recommendation' : 'base',
							action: 'talk-to-broker',
							trigger: isViewingGallery ? 'gallery' : 'expanded-property-card'
						}"
						class="property-contact-button contact-broker-button"
						:background-color="buttonsColor"
						font-family="default"
						is-outlined
						@click="emit('send-get-in-touch-message')"
					>
						<template #before>
							<WhatsAppIcon class="property-contact-button-icon" />
							<span class="button-text">Falar com o corretor</span>
						</template>
					</ButtonBlock>
					<ButtonBlock
						v-if="showScheduleVisitButtons"
						v-cta="{
							commercialId: activePropertyCommercialId,
							propertySource: isFromRecommendation ? 'recommendation' : 'base',
							action: 'schedule-visit',
							trigger: isViewingGallery ? 'gallery' : 'expanded-property-card'
						}"
						class="property-contact-button full-width-on-mobile"
						text="Agendar visita"
						:background-color="buttonsColor"
						font-family="default"
						:is-outlined="false"
						@click="emit('open-schedule-property-visit-modal')"
					>
						<template #before>
							<CalendarIcon class="property-contact-button-icon" />
						</template>
					</ButtonBlock>
				</template>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import type { WebsiteStyles, Color } from '@SHARED/core/entities/WebsiteConfig';

import { getCSSVar } from '@SHARED/utils';

import ButtonBlock from '@SHARED/components/blocks/ButtonBlock.vue';
import AcceptPartnershipButton from '@SHARED/components/atoms/AcceptPartnershipButton.vue';
import BrokerInformation from '@SHARED/components/atoms/BrokerInformation.vue';

import WhatsAppIcon from '~icons/mdi/whatsapp';
import CalendarIcon from '~icons/mdi/calendar-outline';
import ChevronLeft from '~icons/mdi/chevron-left';
import ChevronRight from '~icons/mdi/chevron-right';

defineOptions({ name: 'PropertyNavigationBar' });

type PropertyNavigationBarProps = {
	isViewingGallery?: boolean;
	activePropertyIndex: number | null;
	activePropertyCommercialId?: string | null;
	totalProperties: number;
	shouldShowGetInTouchButton?: boolean;
	acceptPartnershipRequestUrl: string | null;
	brokerName?: string | null;
	brokerEmail?: string | null;
	isFromRecommendation?: boolean;
	showScheduleVisitButtons?: boolean;
};

type PropertyNavigationBarEmits = {
	(e: 'go-to-previous-property'): void;
	(e: 'go-to-next-property'): void;
	(e: 'open-schedule-property-visit-modal'): void;
	(e: 'send-get-in-touch-message'): void;
};

const emit = defineEmits<PropertyNavigationBarEmits>();

const props = withDefaults(defineProps<PropertyNavigationBarProps>(), {
	isViewingGallery: false,
	shouldShowGetInTouchButton: false,
	brokerName: null,
	brokerEmail: null,
	activePropertyCommercialId: null,
	isFromRecommendation: false,
	showScheduleVisitButtons: true
});
const styles = useState<WebsiteStyles>('styles');

const borderRadiusVariant = computed<string>(
	() => styles.value.appearance.defaultBorderRadius || ''
);

const previousButtonText = computed<string | null>(() => {
	if (!props.activePropertyIndex || props.totalProperties === 1) return null;
	if (props.isViewingGallery) return null;

	const previousPropertyNumber = props.activePropertyIndex;

	return `Imóvel anterior (${previousPropertyNumber} de ${props.totalProperties})`;
});

const nextButtonText = computed<string | null>(() => {
	if (props.activePropertyIndex === null) return null;
	if (props.isViewingGallery) return null;

	const isViewingLastProperty =
		props.activePropertyIndex + 1 === props.totalProperties;

	if (isViewingLastProperty || props.totalProperties === 1) {
		return null;
	}

	const nextPropertyIndex = props.activePropertyIndex + 1;

	const nextPropertyNumber = nextPropertyIndex + 1;

	return `Próximo imóvel (${nextPropertyNumber} de ${props.totalProperties})`;
});

type ButtonColor = 'black' | 'white';

const brokerInformationTextColor = computed<ButtonColor>(() =>
	props.isViewingGallery ? 'white' : 'black'
);

const parnetshipButtonColor = computed<ButtonColor>(() =>
	props.isViewingGallery ? 'white' : 'black'
);

const navigationContainerBackgroundColor = computed<string>(() =>
	props.isViewingGallery ? '#1a1a1a' : '#f1f1f1'
);

const navigationWrapperHorizontalPadding = computed<string>(() =>
	props.isViewingGallery ? '0' : '4.25rem'
);

const navigationContainerHorizontalPadding = computed<string>(() =>
	props.isViewingGallery ? '6.75rem' : '2.5rem'
);

const navigationContainerBorderColor = computed<string>(() =>
	getCSSVar(props.isViewingGallery ? 'darkgray' : 'lightgray')
);

const navigationContainerTextColor = computed<string>(() =>
	getCSSVar(props.isViewingGallery ? 'white' : 'black')
);

const buttonsColor = computed<Color>(() =>
	props.isViewingGallery ? 'white' : 'primary'
);
</script>

<style lang="scss" scoped>
@import '@SHARED/assets/style/mixins/breakpoint.scss';

.property-navigation-wrapper {
	position: fixed;
	width: 100%;
	bottom: 0;
	z-index: 35;
	display: flex;
	align-items: center;
	padding: 1.125rem 1rem;
	gap: 0.75rem;

	@include screen-down(lg) {
		background-color: v-bind(navigationContainerBackgroundColor);
		color: v-bind(navigationContainerTextColor);
		border-color: v-bind(navigationContainerBorderColor);
	}

	@include screen-up(lg) {
		padding-left: v-bind(navigationWrapperHorizontalPadding);
		padding-right: v-bind(navigationWrapperHorizontalPadding);
	}
}

.property-navigation-wrapper,
.property-navigation-container {
	height: 5rem;

	@include screen-up(md) {
		height: 5.5rem;
	}
}

.property-navigation-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: v-bind(navigationContainerBackgroundColor);
	color: v-bind(navigationContainerTextColor);
	border-color: v-bind(navigationContainerBorderColor);
	border-style: solid;
	border-top-width: 1px;
	padding: 1.125rem 0 1.125rem 1rem;
	width: 100%;

	@include screen-down(sm) {
		padding: 1rem 0rem;
	}

	@include screen-up(lg) {
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
		padding-left: v-bind(navigationContainerHorizontalPadding);
		padding-right: v-bind(navigationContainerHorizontalPadding);
	}
}

.property-contact-buttons-container {
	display: flex;
	align-items: center;
	gap: 1.5rem;
	width: 100%;
	justify-content: space-between;

	@include screen-down(sm) {
		gap: 0.5rem;
	}

	@include screen-up(lg) {
		width: auto;
	}
}

.property-contact-button {
	height: 2.75rem;
	display: flex;
	align-items: center;
	gap: 0.75rem;
	font-weight: 600;
	font-size: 1rem;
	text-wrap: nowrap;

	@include screen-down(sm) {
		padding: 0.5rem;
	}

	.property-contact-button-icon {
		min-width: 1.5rem;
		width: 1.5rem;
		height: 1.5rem;
	}

	.button-text {
		display: none;
	}

	&.full-width-on-mobile {
		width: 100%;

		@include screen-up(lg) {
			width: auto;
		}
	}

	@include screen-up(lg) {
		height: 2.5rem;

		.button-text {
			display: block;
		}
	}
}

.contact-broker-button {
	height: 2.75rem;
	width: 2.75rem;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.625rem;

	@include screen-up(lg) {
		padding: 0.5rem 1.25rem;
		width: auto;
		height: 2.5rem;
	}
}

.property-carousel-navigation-button {
	display: flex;
	align-items: center;
	transition: all 350ms ease-in-out;
	border: solid 2px var(--primary);
	padding: 0.5rem;
	width: 2.75rem;
	height: 2.75rem;

	@include screen-down(lg) {
		@include border-radius-size();
	}

	.property-carousel-button-icon {
		color: var(--primary);
		width: 1.75rem;
		height: 1.75rem;

		@include screen-up(lg) {
			color: var(--black);
		}
	}

	.button-text {
		display: none;
	}

	@include screen-up(lg) {
		border: none;
		position: absolute;
		top: -6.75rem;
		z-index: 35;
		transform: translateY(-100%);
		padding: 1.625rem 0.625rem;
		width: 3rem;
		height: 5rem;
		gap: 0;
		color: var(--black);
		background-color: var(--white);

		.button-text {
			display: block;
			max-width: 0;
			opacity: 0;
			font-weight: 600;
			font-size: 1.125rem;
			text-wrap: nowrap;
		}
	}

	&:hover {
		filter: brightness(1.1);

		@include screen-up(lg) {
			gap: 0.75rem;
			width: auto;

			.button-text {
				opacity: 1;
				max-width: 15rem;
			}

			&.next {
				padding-left: 1.25rem;
			}

			&.previous {
				padding-right: 1.25rem;
			}
		}
	}

	&.previous {
		justify-content: flex-start;
		left: 0;

		+ .next[data-rounded='square'] {
			border-left-width: 0;

			@include screen-down(lg) {
				margin-left: -0.75rem;
			}
		}
	}

	&.next {
		justify-content: flex-end;
		right: 0;
	}

	.property-carousel-button-icon,
	.button-text {
		transition: inherit;
	}
}

.accept-partnership-button {
	width: 100%;
	margin-left: auto;

	@include screen-up(md) {
		width: auto;
	}
}

.broker-information {
	@include screen-down(lg) {
		display: none;
	}
}
</style>
