<template>
	<div class="date-picker-input-container">
		<CalendarIcon class="calendar-icon" />
		<input
			ref="datePickerInput"
			class="hidden-date-picker-input"
			type="date"
			:min="min"
			:max="max"
			:value="inputDateValue"
			@change="handlePickerInput"
		/>
		<input
			:id="id"
			class="campaign-input default-font"
			type="text"
			placeholder="DD/MM/AAAA"
			:value="modelValue"
			@click="showDatePicker"
			@change="handleTextInput"
		/>
	</div>
</template>

<script setup lang="ts">
import CalendarIcon from '~icons/mdi/calendar-outline';

defineOptions({ name: 'DateInput' });

type Emits = {
	(e: 'update:modelValue', value: string): void;
};

type DateInputProps = {
	modelValue: string | null;
	id: string;
	min?: string;
	max?: string;
};

const emit = defineEmits<Emits>();

const props = defineProps<DateInputProps>();

function createDate(dateString: string): string | null {
	try {
		const [day, month, year] = dateString.split('/').map(Number);
		const date = new Date(year, month - 1, day);

		if (!date) return null;

		return date.toISOString().split('T')[0];
	} catch (error) {
		return null;
	}
}

const inputDateValue = computed<string | null>(() => {
	if (!props.modelValue) return null;

	return createDate(props.modelValue);
});

function handleDateChange(
	event: Event,
	from: 'text-input' | 'date-picker'
): void {
	const element = event.target as HTMLInputElement;

	const date = element.value;

	if (!date) {
		emit('update:modelValue', '');
		return;
	}

	const getDateFormattedFromPicker = (date: string): string => {
		const [year, month, day] = date.split('-');
		return `${day}/${month}/${year}`;
	};

	const brazillianFormattedDate =
		from === 'text-input' ? date : getDateFormattedFromPicker(date);

	emit('update:modelValue', brazillianFormattedDate);
}

function handlePickerInput(event: Event): void {
	handleDateChange(event, 'date-picker');
}

function handleTextInput(event: Event): void {
	handleDateChange(event, 'text-input');
}

const datePickerInput = ref<HTMLInputElement | null>(null);

function showDatePicker(): void {
	if (
		datePickerInput.value &&
		'showPicker' in datePickerInput.value &&
		typeof datePickerInput.value.showPicker === 'function'
	) {
		datePickerInput.value.showPicker();
	}
}
</script>
<style lang="scss" scoped>
.calendar-icon {
	@include screen-down(md) {
		visibility: hidden;
	}

	position: absolute;
	width: 1.25rem;
	height: 1.25rem;
	left: 0.75rem;
	top: 0.75rem;
	color: #666;
	z-index: 1;
}

.campaign-input {
	width: 100%;
	border: 1px solid #ccc;
	font-size: 1rem;
	font-weight: 400;
	padding: 0.75rem;
	line-height: 100%;
	color: #666;
	padding-left: 2.5rem;

	@include screen-down(md) {
		display: none;
	}
}

.date-picker-input-container {
	position: relative;
}

.hidden-date-picker-input {
	padding: 0.75rem;
	font-size: 1rem;
	line-height: 100%;
	width: 100%;
	z-index: -1;
	border: 1px solid #ccc;

	@include screen-up(md) {
		visibility: hidden;
		position: absolute;

		&::-webkit-inner-spin-button,
		&::-webkit-calendar-picker-indicator {
			display: none;
			appearance: none;
			-webkit-appearance: none;
		}
	}
}
</style>
